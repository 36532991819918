.heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #44454d;
}

#Character_shortlist_dropdown .border_bottom [type="text"] {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #44454d;
    /* text-transform: uppercase; */
}

.shortlist_showAll,
.shortlist_showAll .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #44454d;
    font-family: 'Poppins';
}


/* ListCard */

#TalentListCard {
    position: relative;
}

#TalentListCard>.MuiRadio-root {
    position: absolute;
    z-index: 0;
    bottom: 40px;
    width: 100%;
    background-color: rgba(18, 19, 23, .3607843137254902);
    border-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.ListCard img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.ListCard p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    text-transform: capitalize;
    color: #44454d;
    padding: 8px 0px;
    white-space: nowrap;
}


/* TalentMenuCard */

#TalentMenuCard .profile_img {
    border-radius: 10px;
}

#TalentMenuCard .Talent_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-transform: capitalize;
    color: #44454d;
    display: flex;
    align-items: stretch;
}

#TalentMenuCard .addedBy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #44454d;
}

#TalentMenuCard .addedBy>span {
    color: #9b26b6;
}

.talentData_shortlist>p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #44454d;
}

.talentData_shortlist>p>strong {
    font-weight: 600;
}


/* -------------- */

#tablePagination_ui select:focus {
    box-shadow: none;
}

[name="internal_note"] {
    color: #44454d;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

[name="internal_note"]:focus {
    box-shadow: none;
}

.remove_link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #9b26b6;
}

.unlocked_date {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 21px; */
    color: #979797;
}

.shortlist_talent_popup {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    /* identical to box height, or 62% */
    text-transform: capitalize;
    /* Text Grey */
    color: #44454d;
}


/* ShareProjectPopup ---------------- */

#ShareProjectPopup .shareProject_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #9B26B6;
}

#ShareProjectPopup .shareProject_subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #44454D;
}

.project_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;
}

.project_toolTip {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #979797;
}

.request_audition {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* Text Grey */
    color: #44454D;
    margin-left: 10px;
}

.image_container {
    border-radius: 10px;
    height: 200px;
    width: 150px;
}

.image_container>img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    /* height: 210px;
  width: 160px; */
    object-fit: cover;
}

.image_Container:hover .talent_img_view {
    opacity: 1;
}

.image_Container:hover .talent_img_viewed {
    opacity: 0;
}

.shareMorePopup_heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;
}

.shareMorePopup_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;
    word-break: break-all;
    margin-top: 5px;
}

.shareMorePopup_talent_name {
    color: #9B26B6;
}
.make_ofButton {margin-right: 0 !important;}
.resshortlist_page{display: none;}