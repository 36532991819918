@import "~react-image-gallery/styles/css/image-gallery.css";

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background: #f4f4f4;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
  }


/*Footer css start*/
.footer_left_item {
    display: inline-block;
    width: 100%;
}

.common_footer_section {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 64px 0;
    background: #fff;
}
.footer_social_media p{    padding: 6px 0 14px 0;}
.footer_left_item p {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: #000;
    font-weight: 300;
    margin:0;
}
.footer_left_item label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: #000; padding: 15px 0 0px 0;
    font-weight: 300;
    margin:0;
}
.footer_left_item.logomove{margin:6px 0 0 0;}
.footer_left_item ul {
    display: inline-block;
    width: 100%;
}

.footer_left_item .heading {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    padding: 0 0 14px 0;
}

.footer_left_item ul li {
    display: inline-block;
    width: 100%;
    padding: 4px 0;
}

.footer_left_item ul li a {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 300;
}

.footer_left_item ul li a:hover {
    color: #8D16A9;
    font-weight: 500;
}

.footer_social_media ul li {
    display: inline-block;
    padding: 14px 11px 0 0px;
}

.footer_social_media ul li a {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 300;
}

.footer_social_media ul li a:hover {
    color: #8D16A9;
    font-weight: 500;
}

a {
    transition: all 500ms ease-in-out;
}

.relative.user_signuo_button button {
    background: #9B26B6;
    color: #fff;
    padding: 0px 9px;
    border-radius: 32px;
    min-height: 42px;
}

.profileDropDownButton {
    background: #9B26B6;
    color: #fff;
    padding: 0px 9px;
    border-radius: 32px;
    min-height: 42px;
}

.profileDropDownItems {
    font-size: 14px;
    /* color: #000; */
    font-weight: 500;
}

.before_login_links li a {
    font-size: 16px;
    color: #101820;
    font-weight: 500;
}

.before_login_links li.sigin_color a {
    font-size: 14px;
    color: #9B26B6;
    font-weight: 500;
}

.before_login_links li.relative.user_signuo_button .user_name {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    padding: 0 0 0 2px;
}

.object-cover {
    border: 2px solid #fff;
}

.before_login_links li a:hover {
    color: #9B26B6;
}

.before_login_links h2 {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    padding: 0 0 14px 0;
}

.new_header_section ul li a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.new_header_section ul li.haeder_signin a {
    font-size: 14px;
    color: #9B26B6;
    font-weight: 500;
}

.overflow-y-auto {
    background: #F4F4F4;
}

.dshbord_wellcome_text h1 {
    display: inline-block;
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    color: #101820;
    margin: 0px 0 15px 0;
}

.dshbord_wellcome_text label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #9B26B6;
    margin: 0px;
}

.dshbord_wellcome_text p {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #44454D;
    margin: 23px 0 23px 0;
    line-height: 24px;
}

.dshbord_wellcome_text h4 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #101820;
    margin: 15px 0 34px 0;
}

.dshbord_wellcome_text a button {
    background: #9B26B6;
    height: 48px;
    padding: 0 93px;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: inherit;
}

.dshbord_wellcome_text a button:hover {
    background: #363636;
}

.dshbord_wellcome_text {
    display: inline-block;
    width: 100%;
    margin: 8% 0 0 0;
}

.dshbord_wellcome_text h3 {
    font-size: 24px;
    font-weight: 600;
    color: #101820;
    margin-top: 70px;
}

.dshbord_wellcome_text a button {
    margin-top: 40px;
}

.talenter_profile_middle {
    margin: 0 auto;
    width: 1080px;
    margin-bottom: 48px;
}

.outer_bg_section {
    display: inline-block;
    width: 100%;
    padding: 14px 63px;
    padding-bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.step_buttons button {
    width: 50px;
    height: 50px;
    background: #979797;
    position: relative;
    z-index: 2;
}

.step_buttons .StepButton-d0-0-2-6.active {
    background: #9B26B6;
}

.step_buttons .step1 span {
    background: url('../img/personal-data.svg') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
}

.step_buttons .step2 span {
    background: url('../img/physical-description.svg') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 5px 2px;
}

.step_buttons .step3 span {
    background: url('../img/preferences.svg') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
    background-size: 100%;
}

.step_buttons .step4 span {
    background: url('../img/languages-travel.svg') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
    background-size: 100%;
}

.step_buttons .step5 span {
    background: url('../img/skills-education.svg') no-repeat;
    width: 34px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
}

.step_buttons .step6 span {
    background: url('../img/upload-Images.svg') no-repeat;
    width: 34px;
    height: 39px;
    font-size: 0px;
    background-position: -1px 1px;
}

.step_buttons #RFS-LabelContainer span {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    margin: 12px 0 0 0;
}

.talenter_profile_middle #RFS-Connector {
    position: relative;
    border-radius: 24px;
    top: 9px;
}

#RFS-Connector::before {
    background: transparent;
    position: absolute;
    text-align: center;
    display: block;
    left: 0;
    right: 0;
    top: -20px;
    font-family: 'Poppins';
    font-size: 9px;
    font-weight: 600;
    color: #9B26B6;
}

.Connector-d1-0-2-17.active::before {
    content: "17% done"
}

.Connector-d3-0-2-23.active::before {
    content: "33% done"
}

.Connector-d5-0-2-29.active::before {
    content: '50% done'
}

.Connector-d7-0-2-35.active::before {
    content: '66% done'
}

.Connector-d9-0-2-41.active::before {
    content: '83% done'
}

.personal_data_title {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 13px 0 20px 0;
}

.personal_data_title label {
    display: inline-block;
    width: 100%;
    color: #9B26B6;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.personal_data_title h2 {
    display: inline-block;
    width: 100%;
    color: #44454D;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.divider {
    width: 67px;
    height: 3px;
    border-bottom: 4px solid #9b26b6;
    display: inline-block;
    vertical-align: top;
    margin: 6px 0 0 0;
}

.label_text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: #44454D;
    text-align: left;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 0 0 7px 0;
}

.disabled_label_text {
    color: #979797 !important;
}

.personal_data_section .border_bottom .border-gray-300 {
    border: none;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 0px;
    padding-left: 0px;
}

.submit_buttons_bottom {
    display: inline-block;
    width: 100%;
    padding: 0px 0 0 0;
}

.submit_buttons_right {
    float: right;
    width: 100%;
    margin: 35px 0 0px 0;
}

.radio-img {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding: 0 18px 0 0px;
}

.slect_gender {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    vertical-align: top;
    padding: 11px 19px 0 0;
}

.names_list {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
}

.tooltip_msg {
    display: inline-block;
    padding: 0 5px 0 0px;
    float: right;
    vertical-align: middle;
}

.tooltip_msg img {
    display: inline-block;
    vertical-align: middle;
}

.personal_data_section em, .top_profile_name #PersonalData em {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    font-style: inherit;
    text-align: left;
    color: #979797;
}

.submit_buttons_bottom [type='checkbox'] {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.form_center_img {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 18px 0 0 0;
}

.form_center_img img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.left_slider {
    float: left;
    width: 67%;
    margin: 9px 0 0 0;
}

.right_button {
    float: right;
    width: 26%;
    overflow: hidden;
    border-radius: 4px;
}

.form_right .label_text {
    margin: 4px 0 7px 0;
}

.button_in {
    float: left;
    background: #9B26B6;
    border-radius: 5px 0px 0px 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #fff;
    height: 30px;
}

.button_cm {
    float: left;
    background: #F1F1F1;
    border-radius: 0px 5px 5px 0px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #979797;
    height: 30px;
}


.physical_button_active {
    float: left;
    background: #9B26B6;
    border-radius: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #fff;
    height: 30px;
}

.physical_button_inactive {
    float: left;
    background: #F1F1F1;
    border-radius: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #979797;
    height: 30px;
}


.talenter_profile_middle .StepButton-d2-0-2-12.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d0-0-2-6.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d4-0-2-20.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d6-0-2-26.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d2-0-2-12.completed:hover {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d2-0-2-12.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d4-0-2-20.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d6-0-2-26.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d8-0-2-32.active {
    background-color: #9B26B6;
}

.border_bottom select {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.border_bottom [type='text'],
.border_bottom textarea ,.pills_dropdown [type='text'],
.pills_dropdown textarea{
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    box-shadow: none;
}

.disabled_border_bottom [type='text'] {
    color: #44454D;
    opacity: 0.7 !important;
}

.color_fad {
    font-size: 12px;
    color: #c9c9c9;
    display: inline-block;
    font-weight: 400;
}

.edu_title {
    font-size: 18px;
    font-weight: 600;
    color: #9B26B6;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.label_text.top_more_space {
    margin: 0 0 16px 0;
}

.edu_title.move_bottom {
    display: inline-block;
    width: 100%;
    margin: 30px 0 0 0;
}

.edu_title.move_top_bot {
    margin: 28px 0 26px 0;
}

.phone_number {
    float: left;
    width: 65%;
    margin: 7px 0 0 0;
}

.verfiy_buttons {
    float: left;
    width: 36%;
}

.verfiy_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../img/whatsapp.png') no-repeat;
    vertical-align: middle;
    margin: -1px 8px 0 0;
}

.outer_bg_section .verfiy_buttons button {
    border: 1px solid #9B26B6;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #9B26B6;
    align-items: flex-start;
    padding: 8px 15px 8px 12px;
    background: #fff;
    height: 36px;
    line-height: 19px;
    text-transform: inherit;
    width: 113px;
    border-radius: 4px;
    float: right;
    vertical-align: middle;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
}

/* .verify_mobile_button .MuiButton-root  {
    border: 1px solid #9B26B6;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #9B26B6;
    align-items: flex-start;
    padding: 8px 15px 8px 12px;
    background: #fff;
    height: 36px;
    line-height: 19px;
    text-transform: inherit;
    width: 113px;
    border-radius: 4px;
    float: right;
    vertical-align: middle;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
} */

.verfiy_buttons button:hover {
    background: #fbfbfb;
}

.what_up_date {
    float: left;
    width: 100%;
    margin: 12px 0 0 0;
}

.what_up_date_rec {
    float: left;
    width: 100%;
    margin: 12px 0 0 0;
}

.what_up_date span {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0 0 0 12px;
}

.what_up_date_rec span {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0 0 0 12px;
}

.border_bottom [type='file'] {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    width: 100%;
    height: 32px;
    margin: 10px 0 0 0;
}

/* .new_header_section{padding:22px 0;} */
.prod_year {
    float: left;
    width: 93%;
}

.add_more_button {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/icon.plus.png') 0px 7px no-repeat;
    padding: 11px 0 12px 31px;
    border: none;
}

.home_page_banner {
    display: inline-block;
    width: 100%;
    position: relative;
}

.left_home_banner_content h1 {
    color: #101820;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left;
}

.left_home_banner_content p {
    color: #666C72;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin: 7px 0 60px 0;
}

.left_home_banner_img {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 8% 0 0 0;
}

.left_home_banner_img img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.find_button button {
    background: #9B26B6;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.work_button button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.our_clients h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    display: inline-block;
    width: 100%;
    margin: 0 0 13px 0;
}

.our_clients ul {
    display: inline-block;
    width: 100%;
}

.our_clients ul li {
    display: inline-block;
    width: 20%;
}

.left_home_banner_content {
    display: inline-block;
    width: 100%;
    padding: 17% 0 0 0;
    position: relative;
    z-index: 1;
}

.unique_roles_section {
    background: linear-gradient(149.98deg, #44454D 17.8%, #9B9EA0 73.02%);
    display: inline-block;
    width: 100%;
    min-height: 500px;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}

/* 
.relative.user_signuo_button .absolute {position:fixed; right:0px; width:350px;    height: 100vh;
overflow-y: auto;-webkit-overflow-scrolling: touch;    outline: 0;}

.relative.user_signuo_button .absolute {
right: -350px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.relative.user_signuo_button .enter.enter-active .absolute{
right: 0;
} */
.unique_roles_section:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 39%;
    height: 100%;
    top: 0px;
    right: -158px;
    -webkit-transform: skewX(22deg);
    -ms-transform: skewX(22deg);
    transform: skewX(22deg);
    z-index: 1;
}

.talent_left_info h3 {
    display: inline-block;
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.talent_left_info p {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.talent_left_active_users {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    min-height: 540px;
    position: relative;
    z-index: 1;
    margin: 11% 0;
    padding: 35px 47px;
}

.talent_left_info {
    display: inline-block;
    width: 83%;
    position: relative;
    z-index: 2;
    padding: 18% 0 0 0;
}

.talent_left_active_users h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
}

.talent_left_active_users p {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    margin: 5px 0 13px 0;
}

.users_item {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.users_item img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.header_icon {
    position: absolute;
    top: 14px;
    right: 27px;
}

.img_list {
    display: inline-block;
    width: 100%;
    position: relative;
}

.users_item label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
    text-align: center;
    margin: 7px 0 0 0;
}

.unique_roles_section.show_your_talent:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 39%;
    height: 100%;
    top: 0px;
    left: -158px;
    -webkit-transform: skewX(22deg);
    -ms-transform: skewX(22deg);
    transform: skewX(22deg);
    z-index: 1;
}

.unique_roles_section.show_your_talent {
    background: linear-gradient(149.98deg, #8D16A9 17.8%, #BB29BB 73.02%);
}

.unique_roles_section.show_your_talent .talent_left_info {
    padding: 18% 0 0 8%;
}

.unique_roles_section.show_your_talent:after {
    position: absolute;
    right: 0px;
    bottom: 0px;
    content: '';
    width: 100%;
    height: 100%;
    background: url('../img/start_ratings.png') right bottom no-repeat
}

.unique_roles_section:after {
    position: absolute;
    left: 0px;
    bottom: 0px;
    content: '';
    width: 100%;
    height: 100%;
    background: url('../img/start_ratings.png') left bottom no-repeat;
}

.left_label {
    display: inline-block;
    width: 30%;
}

.right_text_feild {
    display: inline-block;
    width: 70%;
}

.left_label span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.submit_before_login {
    float: right;
    width: 70%;
}

.right_text_feild .leading-5 {
    line-height: 28px;
}

.submit_before_login p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.submit_before_login .submit_button {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #101820;
    height: 47px;
    padding: 0 31px;
    margin: 16px 0 0 0;
}

.submit_before_login .submit_button:hover {
    background: linear-gradient(149.98deg, #8D16A9 17.8%, #BB29BB 73.02%);
    color: #fff;
}

.top_tobottom {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 0 0 32px 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.top_tobottom img {
    display: inline-block;
    text-align: center;
}

.our_clients {
    display: inline-block;
    width: 100%;
    margin: 26px 0 36px 0;
    position: relative;
    z-index: 1;
}

#header_fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
}

.common_height {
    display: inline-block;
    width: 100%;
    height: 67px;
}


.border_bottom [type='text']::-webkit-input-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']::-moz-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']:-ms-input-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']:-moz-placeholder {
    color: #9f9f9f;
}


.after_signup_section .left_profile {
    /* overflow: hidden; */
    width: 60px;
    height: 50px;
    /* border-radius: 50px; */
    /* float: left; */
    vertical-align: middle;
    margin-right: 10px;
}

.right_profile_info {
    float: left;
}

.after_signup_section svg {
    display: none;
}

.after_signup_section li a span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.right_profile_info h4 {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    vertical-align: top;
}

.right_profile_info label {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    vertical-align: top;
}

.relative.user_signuo_button .after_signup_section {
    /* White */
    background: #FFFFFF;
    /* 5px Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.after_signup_section li {
    margin: 0px 0 4px 0;
    padding: 0px;
}

.relative.user_signuo_button .after_signup_section {
    padding: 15px 11px;
}

.footer_leftpadding {
    padding: 0 0px 0 40px;
}

.home_page_banner:before {
    position: absolute;
    left: 0px;
    top: 0px;
    content: '';
    width: 645px;
    height: 100%;
    background: url('../img/home_page_banner.png') left bottom no-repeat;
}

.home_page_banner:after {
    position: absolute;
    left: 7%;
    top: -221px;
    content: '';
    width: 1060px;
    height: 100%;
    background: url('../img/home_banner_star.png') left bottom no-repeat;
}

.top_tobottom button {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.outer_bg_section .MuiFormControl-root,
.outer_bg_section .MuiInput-root {
    width: 100%;
}

.gender_list .radio-img .MuiRadio-root {
    display: none;
}

.gender_list .radio-img span input:checked+.image {
    vertical-align: top;
    border-radius: 40px;
    cursor: pointer;
    border: 1px solid red;
}

/* 
.switch_design .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 11px;
} */
/* 
.uplode_file .MuiButton-root {
    width: 100%;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    border: 1px solid #9B26B6;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    height: 36px;
    text-transform: inherit;
    box-shadow: none;
} */

/* .uplode_file .MuiButton-root:hover {
    background: #fff;
} */

.to_add_style {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.submit_buttons_bottom .cancel_button {
    height: 40px;
    width: 118px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px #0000000D;
    border: 1px solid #E5E5E5;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: inherit;
    letter-spacing: 0em;
    text-align: center;
    padding: 0px;
    color: #44454D;
}

.submit_buttons_bottom .submit_button,
.submit_buttons_bottom .submit_button.Mui-disabled {
    height: 40px;
    width: 155px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
    padding: 0px;
    background: #9B26B6;
    float: right;
    color: #fff;
}

.submit_buttons_bottom .submit_button:hover {
    background: #9B26B6;
}

.submit_buttons_bottom .upload_img_button {
    height: 40px;
    width: fit-content;
    border: solid 1px #9B26B6;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
    padding: 0px 15px;
    background: #FFFFFF;
    float: right;
    color: #9B26B6;
}

.submit_buttons_bottom .upload_img_button:hover {
    background: #FFFFFF;
}

.uplode_icon {
    display: inline-block;
    width: 14px;
    height: 23px;
    background: url(../img/down.png) 0px 3px no-repeat;
    vertical-align: middle;
    padding: 0 11px;
}

.uplode_file {
    display: inline-block;
    margin: 0px 0 0 0;
    width: 100%;
}

.m-left {
    margin: 0.2rem !important;
}

.terms_and_condition_section {
    display: inline-block;
    width: 100%;
    margin: 0px 0 0 -10px;
}

.afterclick_checkbox {
    display: inline-block;
    width: 100%;
    margin: 17px 0 0 0px;
}

.switch_design {
    display: inline-block;
    margin: 31px 0 0 11px;
    
}
.switchLabel {
    
    color: #44454D;
}

.mt20 {
    display: inline-block;
    width: 100%;
    margin: 0px 0 26px 0;
}

.terms_and_condition_section .PrivateSwitchBase-input svg {
    color: #e1e1e1;
}

.shadow-bottom {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.relative.user_signuo_button .MuiAvatar-root {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    width: 33px;
    overflow: hidden;
}

.user_name {
    display: inline-block;
    vertical-align: middle;
    padding: 0 6px 0 4px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.user_name .MuiAvatar-img {
    width: 60px;
    height: 60px;
}

/* .relative.user_signuo_button .css-1pqm26d-MuiAvatar-img {
    border: 3px solid #fff;
    border-radius: 38px;
} */

.border_bottom .MuiSlider-root {
    color: #9b26b6 !important;
    height: 3px;
}

.form_left .MuiSlider-valueLabelOpen,
.form_right .MuiSlider-valueLabelOpen {
    background: transparent;
    top: 1px;
}

.form_left .MuiSlider-valueLabelLabel,
.form_right .MuiSlider-valueLabelLabel {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
}

.form_left .css-1kz0hui-MuiSlider-valueLabel:before {
    display: none;
}

.border_bottom .slider {
    margin-bottom: 10px;
}

.slider_range {
    padding: 0 0px 0 0px;
}

.terms_and_condition_section svg {
    color: #dbdada;
}


.personal_data_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before,
.personal_data_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgb(209 209 209 / 42%);
}

.persentage {
    position: absolute;
    top: 0px;
}

.StepButton-d0-0-2-6.completed:hover {
    background-color: rgb(155 38 182);
}

.StepButton-d2-0-2-12.active:hover {
    background-color: rgb(155 38 182);
}


.personal_data_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(155 38 182);
}



.new_header_section ul li a:hover {
    color: #9B26B6;
}

.talenter_profile_middle .StepperContainer-0-2-1 {
    padding: 24px 0 19px 0;
}

.personal_data_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(155 38 182);
}

.personal_data_section .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: rgb(255 255 255 / 5%);
    border-radius: 0;
}

.calender_common {
    display: inline-block;
    width: 100%;
    margin: 3px 0 0 0;
}

.day_list,
.month_list,
.year_list {
    display: inline-block;
    width: 60px;
    margin: 0 13px 0 0;
}

.eventIcon_icon {
    display: inline-block;
    width: 60px;
    position: relative;
}

.eventIcon_icon svg {
    color: #979797;
    font-size: 26px;
}

.talenter_profile_middle .StepButton-d12-0-2-44.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d14-0-2-48.active {
    background-color: rgb(237, 29, 36) !important;
}

.talenter_profile_middle .StepButton-d14-0-2-48.active {
    background-color: rgb(155 38 182) !important;
}

.talenter_profile_middle .StepButton-d14-0-2-48.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d16-0-2-54.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d18-0-2-60.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d20-0-2-66.active {
    background-color: rgb(155 38 182);
}

.border_bottom.production_year .label_text {
    width: 93%;
}

.add_more_items .focus\:ring:focus {
    border: none;
    outline: none;
}

.talenter_profile_middle .StepButton-d8-0-2-32.completed {
    background-color: rgb(155 38 182) !important;
}

.talenter_profile_middle .StepButton-d10-0-2-38.active {
    background-color: rgb(155 38 182) !important;
}

/* .images_uploders{display:inline-block; width:100%;} */
.images_uploders {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.left_cover_images {
    display: inline-block;
    width: 100%;
    text-align: center;
    background: #E5E5E5;
    position: relative;
    /* height: 520px; */
}

.image_item {
    display: inline-block;
    width: 100%;
    background: #E5E5E5;
    height: auto;
    text-align: center;
    position: relative;
}

.image_items_bg_none {
    background: rgba(229, 229, 229, 0) !important
}

.step_buttons {
    margin: 0 auto;
    width: 716px;
}

/* .outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:after{border-bottom: 2px solid #9b26b6;}
.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after{border-bottom: 2px solid #9b26b6;} */
.eventIcon_icon .MuiButton-startIcon {
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.eventIcon_icon .MuiButton-standard.MuiButton-standardPrimary {
    min-width: 49px;
}

.border_bottom_fixed {
    display: inline-block;
    width: 100%;
}

.border_bottom_fixed .label_text {
    display: inline-block;
    width: 64%;
}

.border_bottom_fixed_w100 .label_text {
    display: inline-block;
    width: 100%;
}

.eventIcon_icon .MuiButton-startIcon svg {
    font-size: 25px;
}

/* .new_header_section {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 1400;
    min-height: 73px;
} */

.css-zw3mfo-MuiModal-root-MuiDialog-root {
    z-index: 1400 !important;
}


.css-126xj0f {
    z-index: 1400 !important;
}

.left_slider .MuiSlider-rail {
    opacity: 0.1;
}

.add_more_button:focus {
    outline: none;
    border: none;
    --tw-ring-color: #fff;
}


.outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(155 38 182);
}

.outer_bg_section [type='text']:focus,
.outer_bg_section select:focus {
    border-color: #ffffff;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
}

.sign_opt_inner [type='text']:focus {
    border-color: #F4F4F4;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #F4F4F4;
    --tw-ring-color: #F4F4F4;
}


.reason_text [type='text']:focus {
    border-color: #ffffff;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
}

.outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before,
.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgb(209 209 209 / 42%);
}

.outer_bg_section .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: rgb(255 255 255 / 5%);
    border-radius: 0;
}

.progros_bar {
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
}

.tooltip_contianer {
    font-family: Poppins;
    font-size: 14px;
    color: #44454D;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.tooltip_contianer.jobAudition {
    background: #fff;
    /* box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
     */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 19%) 0px 2px 16px 0px;
    border-radius: 10px;
    padding: 8px;
    position: absolute;
    width: 100%;
    min-width: 143px;
    max-width: 143px;
    z-index: 100;
    right: auto;
}

.tooltip_contianer.jobAudition:before {
    content: "";
    width: 12px;
    height: 12px;
    background: #fff;
    display: block;
    position: absolute;
    bottom: -6px;
    transform: matrix(0.71, 0.73, -0.69, 0.71, 0, 0);
    z-index: 99;
    left: 47%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    /* right: 3px; */
}
.tooltip_contianer.LockIcon:before {
    bottom: -3px;
    right: 6px;
}

.position_abst {
    position: absolute;
    width: 0;
}

/* .Mui-selected{background-color: #9b26b6!important; color:#fff!important;} */
.personal_data_section .MuiInput-root.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182) !important;
}

.border_bottom {
    position: relative;
}

.custom_dropdown_icons.skin_color .icon__box {
    width: 19.6%;
}

.custom_dropdown_icons.eye_color .icon__box {
    width:23.6%;
}

.icon__box:hover {
    background: #fbfbfb;
}

.custom_dropdown_icons.eye_color .dropdown__icons__list {
    width:422px;
}

.custom_dropdown_icons.hair_color .dropdown__icons__list {
    width: 500px;
}

.outer_bg_section input:-internal-autofill-selected {
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
}

.outer_bg_section .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 1px solid rgb(209 209 209 / 42%);
}

.talenter_profile_middle .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    height: 1.4375em;
}

.slider_range span.MuiSlider-thumbColorPrimary {
    color: #9b26b6;
    width: 15px;
    height: 15px;
}

.talenter_profile_middle.imagUpload_cont {
    width: 100%;
}

.outer_bg_section .MuiFormControl-root .MuiInput-root .MuiInput-underline {
    border-bottom: 2px solid rgb(155 38 182);
}

/* .personal_data_section .MuiInputBase-colorPrimary.Mui-focused{border-bottom:1px solid #9b26b6;} */
.outer_bg_section_data .MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%);
}

.outer_bg_section_data .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(155 38 182);
}

/* .personal_data_section .MuiInputBase-colorPrimary.Mui-focused{border-bottom:2px solid #9b26b6;} */

.talenter_profile_middle .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid rgb(155 38 182);
}

.talenter_profile_middle .MuiInputBase-colorPrimary.Mui-focused::after {
    border-bottom: none;
}

/* .personal_data_section [type='text']:focus, .personal_data_section select:focus{
	--tw-ring-inset: var(--tw-empty,--tw-ring-offset-color: #fff;--tw-ring-color: #ffffff;} */
.personal_data_section .switch_design .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(19px);
    -moz-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px)
}

.outer_bg_section .MuiFormControlLabel-root {
    margin-left: 0px;
}

.outer_bg_section .MuiInput-underline:before {
    border-bottom: 1px solid rgb(197 197 197 / 42%);
}

.outer_bg_section .MuiInput-root.MuiInput-underline:before {
    border-bottom: 1px solid rgb(197 197 197 / 42%);
}





.top_right_nave {
    display: inline-block;
    width: 100%;
    margin: 4px 0;
    vertical-align: top;
}

.top_right_nave ul {
    float: right;
    padding: 0px;
    margin: 0px;
}

.top_right_nave ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.top_right_nave ul li a {
    display: inline-block;
    margin: 0px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #44454D;
}

.top_right_nave ul li a:hover {
    color: #9B26B6;
}

.top_title h2,.top_title h1  {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.top_profile_section {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 22px 0;
}

.middle_bar_section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background: #fafafa;
    padding: 33px 0;
}

.left_side_bar ul {
    float: right;
    padding: 0px;
    margin: 0px;
}

.left_side_bar ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.left_side_bar ul li a {
    display: inline-block;
    margin: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    vertical-align: middle;
    padding: 11px 0;
    padding-left: 51px;
}

.data_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../img/camera_icon.png) 0 0 no-repeat;
    vertical-align: middle;
    margin: 0 13px 0 0;
}

.data_icon.data_icon2 {
    background: url(../img/icon.user.svg) no-repeat;
}

.data_icon.data_icon3 {
    background: url(../img/icon_ruler.png) no-repeat;
}

.data_icon.data_icon4 {
    background: url(../img/icon_userseettings.png) no-repeat;
}

.data_icon.data_icon5 {
    background: url(../img/icon_education.png) no-repeat;
}

.data_icon.data_icon6 {
    background: url(../img/icon_videogallery.png) no-repeat;
}

.left_side_bar ul li a:hover {
    background: #E5E5E5;
    color: #9B26B6;
}

.profile_name_left h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
}

.profile_name_left h4 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
}

.validations {
    display: inline-block;
    width: 33px;
    height: 33px;
    background: url(../img/icon_validated.png) 0 -2px no-repeat;
    vertical-align: middle;
}

.profile_click .MuiButton-text {
    background: url(../img/three_dots.png) center center no-repeat;
    padding: 0px;
    height: 24px;
    width: 25px;
    padding: 0px 0;
    vertical-align: middle;
}

.profile_click {
    display: inline-block;
    text-align: center;
}

.top_profile_name {
    display: inline-block;
    width: 860px;
}

.profile_name__left h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    display: inline-block;
}

.com__scor {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    color: #44454D;
}

.profile_name__left {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 0 12px 0;
}

.profile_compliction_scor {
    display: inline-block;
    width: 100%;
    padding: 28px 0 0px 0;
}

.profile_name__accright {
    float: right;
    vertical-align: middle;
}

.profile_name__accright label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    color: #44454D;
    padding: 0 23px 0 0;
}

/* .profile_name__accright .MuiButton-root {

    background: #FFFFFF;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    width: 80px;
    text-transform: inherit;
} */

/* .profile_name__accright .MuiButton-root:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
} */

.profile_name__accd h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    display: inline-block;
    width: 100%;
}

.under_line {
    display: inline-block;
    width: 60px;
    height: 3px;
    background: #9B26B6;
    vertical-align: top;
    margin-top: 7px;
}

.upperarrow {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url(../img/upper_arrow.png) 0 2px no-repeat;
    margin: 0 0px 0 33px;
}

.act__user_profile_top {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    padding: 0px 0 20px 0;
}

.list_img {
    display: inline-block;
    padding: 0 6px;
    margin-bottom: 15px;
    float: left;
    width: 20%;
}

.act__user_profile_glary .slick-dots li button:before {
    font-size: 11px;
    opacity: 1;
    color: #EDEDED;
}

.act__user_profile_glary .slick-dots li {
    margin: 0 0px;
}

.act__user_profile_glary .slick-dots li.slick-active button:before {
    color: #9B26B6;
    ;
}

.personaldata_profile_top {
    display: inline-block;
    width: 100%;
}

.outer_form_cntrol {
    display: inline-block;
    width: 100%;
}

.ach_title {
    display: inline-block;
    width: 100%;
}

.ach_title {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 36px 0 6px 0;
}

.ongoing_project_list {
    display: inline-block;
    width: 100%;
    padding-top: 24px;
    padding-left: 40px;
}

.ongoing_project_list h4 {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.ongoing_project_list label {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}

.ongoing_project_list p {
    font-family: Poppins;
    font-size: 16px;
    color: #44454D;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
}

.ongoining_projectrs .slick-dots li button {
    padding: 0px;
    padding: 0px;
}

.ongoining_projectrs .slick-dots {
    padding: 0px;
    padding: 0px;
    position: inherit;
}

.ongoining_projectrs .slick-dots li button:before {
    font-size: 11px;
}

.ongoining_projectrs .slick-dots li.slick-active button:before {
    color: #9b26b6;
    opacity: 1;
}

.ongoining_projectrs .slick-dots li button:before {
    color: #b7b7b7;
}

.ongoining_projectrs .slick-dots li {
    width: 10px;
}

.outer_bg_section_data .MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%);
}

.outer_bg_section_data .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(155 38 182);
}

.personal_data_section .MuiInputBase-colorPrimary.Mui-focused {
    border-bottom: none;
}

/* .talenter_profile_middle .MuiInputBase-colorPrimary.Mui-focused::after{border-bottom: 2px solid rgb(155 38 182);} */
.top_profile_name .MuiInput-underline.MuiInputBase-root.Mui-focused:after {
    border-bottom: none;
}

.top_profile_name [type='text']:focus,
.top_profile_name select:focus {
    --tw-ring-offset-color: #f4f4f4;
    --tw-ring-color: #f4f4f4;
}

.outer_bg_section_data .MuiTabPanel-root {
    padding: 0px;
}

.outer_bg_section_data .MuiButtonBase-root {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    text-transform: inherit
}

.outer_bg_section_data .MuiTabs-fixed {
    text-align: center;
}

.inner_video h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 24px 0 0 0;
}
.printShootCount {
        font-family: Poppins;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: end;
        color: #44454D;
        font-size: 14px;
        color: #979797;
        margin-top: 2px;
}

.inner_video label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 0px 0 16px 0;
}

.at_time {
    color: #979797;
}

.video_img img {
    max-width: 100%;
    border-radius: 14px;
    width: 100%;
    height: 100%;
}

.printShoot_placeholder img {
    max-width: 100%;
    border-radius: 14px;
    width: 100%;
    height: 100%;
}

.inner_video {
    display: inline-block;
    width: 95%;
    padding: 0px 0 20px 0;
    position: relative;
}

.inner_video div {
    width: 100% !important;
    height: 241px !important;
}

.middle_bar_section .MuiBox-root,.recruiterPastwork .MuiBox-root {
    border: none;
}

/* .middle_bar_section .Mui-selected {color: #9b26b6!important;} */
.middle_bar_section .MuiTabs-indicator,.recruiterPastwork .MuiTabs-indicator {
    background-color: #9b26b6;
}

.intro_video_section .slick-dots li button {
    padding: 0px;
    padding: 0px;
}

.intro_video_section .slick-dots {
    padding: 0px;
    padding: 0px;
}

.intro_video_section .slick-dots li button:before {
    font-size: 11px;
}

.intro_video_section .slick-dots li.slick-active button:before {
    color: #9b26b6;
    opacity: 1;
}

.intro_video_section .slick-dots li button:before {
    color: #b7b7b7;
}

.intro_video_section .slick-dots li {
    width: 10px;
}

.remove_icon {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/remove_icon.svg') top left no-repeat;
    padding: 2px 0 0 32px;
    margin: 9px 0 0 0;
}

.addMore_btn {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    padding: 2px 0 0 32px;
    margin: 9px 0 0 0;
    vertical-align: center;
}

.addMore_btn.remove_padding {
    padding: 2px 0 0 0 !important;
}

.short_flime .inner_video {
    padding: 0px 0 20px 0;
}

.short_flime {
    display: inline-block;
    width: 100%;
    padding: 30px 0 0;
}

.add_more_section {
    display: inline-block;
    width: 100%;
    padding: 0;
}

.add_more_section .addmore {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/add_more.svg') top left no-repeat;
    padding: 2px 0 0 32px;
}

.das_list {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    min-height: 120px;
    padding: 28px 0;
    margin-bottom: 28px;
    transition: .3s;
}

.das_list:hover {
    box-shadow: 0px 18px 16px rgb(221 221 221 / 40%);
    transform: scale(1.05, 1.05);
}

.right_new_icon label {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}

.right_new_icon b {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.left_new_icon {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}

.left_new_icon img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    text-align: center;
}

.top_profile_section_bot_list {
    display: inline-block;
    width: 100%;
    background: #f4f4f4;
    padding-top: 1.75rem;
}

.das_list.last_box_section {
    padding: 38px 0;
}

.bottom_space {
    display: inline-block;
    width: 100%;
    min-height: 50px;
}

.top_search_section {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    min-height: 160px;
    padding: 0 39px 40px;
}

.table_count_search_section {
    display: inline-block;
    width: 100%;
    margin: 15px 0 0 0;
}

.table_count_search_section h2 {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.nwswitch {
    display: inline-block;
    padding: 0 0 0 18px;
}

.table_count_search_section .MuiTypography-root {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.table_count_search_section .MuiButton-outlined {
    float: right;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    text-transform: inherit;
    background: #fff;
    margin: 34px 0 0 0;
}

.top_search_section .MuiDataGrid-columnHeaderTitle {
    font-family: Poppins;
    font-size: 12px;
    color: #44454D;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.top_search_section .MuiDataGrid-cellContent {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.top_search_section .css-f3jnds-MuiDataGrid-columnHeaders {
    border-bottom: 1px solid rgb(239 239 239);
}

.top_auto_search {
    display: inline-block;
    width: 100%;
}

.top_profile_section_bot_list .MuiFormControl-root {
    width: 100%;
}

.top_search_section_table {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    min-height: 160px;
}

.submit_buttons .MuiButton-containedPrimary {
    width: 100%;
    background: #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    height: 40px;
    text-transform: inherit;
    margin: 41px 0 0 0;
}

.submit_buttons .MuiButton-containedPrimary:hover {
    background: #222;
}

.MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%) !important;
}
.value_broder .MuiInput-underline:before {
    border-bottom: 1px solid #9b26b6  !important;
    /* border-bottom-color: #FF0000 !important; */
  }
  .value .MuiInput-underline:before {
    border-bottom: 1px solid #9b26b6  !important;
    /* border-bottom-color: #FF0000 !important; */
  }
.outer_bg_section_data .MuiFormControl-root,
.outer_bg_section_data .MuiInput-root {
    width: 100%;
}

.gender_list .radio-img .MuiRadio-root {
    display: none;
}

.gender_list .radio-img span input:checked+.image {
    vertical-align: top;
    border-radius: 40px;
    cursor: pointer;
    border: 1px solid red;
}

.formLabelText .MuiTypography-root {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    font-family: 'Poppins';
    text-align: left;
    padding: 0 0 0 11px;
    color: #44454D;
}

/* 
.uplode_file .MuiButton-root {
    width: 100%;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    border: 1px solid #9B26B6;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    height: 36px;
    text-transform: inherit;
    box-shadow: none;
} */

/* .uplode_file .MuiButton-root:hover {
    background: #fff;
} */

/* .middle_bar_section .Mui-selected {color: #9b26b6!important;} */
.middle_bar_section .MuiTabs-indicator {
    background-color: #9b26b6;
}

.custom_dropdown_icons {
    position: relative;
}

.MuiInput-input.MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: inherit;
}

.MuiInput-root.MuiInput-underline:after {
    border-bottom: 1px solid #9B26B6;
}


.talenter_profile_middle .Mui-focused {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.top_heading_content h2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    display: inline-block;
    width: 100%;
}

.top_heading_content label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #44454D;
    display: inline-block;
    margin: 25px 0 0 0;
}

.top_heading_content .color_change {
    font-weight: 600;
    color: #9B26B6;
}

.top_heading_content {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 17px 0 0 0;
}

.opt_left {
    display: inline-block;
    width: 40px;
    margin: 0 4px;
}

.top_heading_content .MuiInput-root.MuiInput-underline:after {
    border-bottom: 1px solid #9B26B6;
}

.top_heading_content [type='text']:focus {
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: transparent;
}

.top_heading_content [type='text'] {
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
}

.relode_the_buttons {
    display: inline-block;
    width: 100%;
    margin: 34px 0 8px 0;
    vertical-align: middle;
}

.resend_but a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/iconrefresh.png') no-repeat;
    padding: 1px 0 0 31px;
}

.resend_but {
    display: inline-block;
    text-align: center;
}

.relode_the_buttons {
    text-align: left;
}

.time_count {
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #979797;
}

.submit_buttons_popup {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 23px 0 3px 0;
}

/* .submit_buttons_popup .MuiButton-root.MuiButton-outlined {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    float: left;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    height: 40px;
    background: #E5E5E5;
    text-transform: inherit;
    padding: 0 52px;
} */

/* .submit_buttons_popup .MuiButton-root.MuiButton-contained {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    border-radius: 5px;
    height: 40px;
    background: #9B26B6;
    padding: 0 52px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    color: #fff;
    float: right;
    text-transform: inherit;
} */

.opt_inner {
    display: inline-block;
    width: 100%;
    margin: 27px 0 10px;
}

.sign_opt_inner {
    width: 100%;
    margin: 13px 0 10px;
    display: flex;
}

.ongoining_projectrs {
    display: inline-block;
    width: 100%;
}

.outer_bg_section_data .Mui-selected {
    color: #9B26B6 !important;
}

.drop_down_menu_list ul li a {
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
    padding: 7px 20px;
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list ul li {
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list ul {
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list {
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
    width: 180px;
    background: #FFFFFF;
    padding: 11px 0;
    /* 5px Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.profile_click:hover .drop_down_menu_list {
    display: block;
}

.drop_down_menu_list ul li a img {
    display: inline-block;
}

.drop_down_menu_list {
    text-align: left;
}

.talenter_profile_middle .MuiStepConnector-line {
    height: 4px !important;
    border-radius: 40px !important;
}

.talenter_profile_middle .MuiStepConnector-horizontal {
    border-radius: 40px !important;
}

.talenter_profile_middle .MuiStepLabel-iconContainer .css-1mr67r1 {
    background-color: #979797;
}

.border_bottom .MuiInputBase-colorPrimary.Mui-error:after {
    border-bottom: 1px solid #FF0000
}

.bold_text {
    font-weight: 600;
}



.top_user_info img, .top_profile_info img {
    border: 0;
}

.top_user_info {
    background: #7D1396;
    padding: 5px;
}

.top_profile_info {
    padding: 3px;
}

.form_left.left_move_mt {
    margin: 34px 0 0 0;
}

.form_left.left_move_mt .MuiSlider-rail {
    opacity: 0.1;
}

.label_text.move_mt10 {
    padding: 8px 0 4px 0;
}

.MuiAlert-filledSuccess {
    background: #9b26b6 !important;
}



/*new Similar profiles css*/
.similar_profiles {
    display: inline-block;
    width: 100%;
    margin-top: 33px;
}

.similar_profiles h3 {
    display: inline-block;
    width: 100%;
    font-family: "Poppins";
    /* padding: 34px 0 13px 35px; */
    padding: 0 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}
.similar_profiles .similar_img {
    aspect-ratio: 3/4;
    object-fit: cover;
    border-radius: 10px;
    width: 120px;
    height: 162px;
}

.similar_profiles h3 .border_bottom {
    display: inline-block;
    background: #9B26B6;
    height: 3px;
    width: 60px;
    vertical-align: top;
}

.new_sim_photo label {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    vertical-align: top;
    display: inline-block;
    position: relative;
    padding: 0 33px 0 0px;
    margin: 9px 0 19px 0;
    color: #44454D;
}

.new_sim_photo img {
    display: inline-block;
    max-width: 100%;
}

.tick_buttons {
    display: inline-block;
    width: 100%
}

.new_sim_photo label:before {
    background: url('./../img/nicon_validated.png') no-repeat;
    position: absolute;
    width: 26px;
    height: 26px;
    content: '';
    right: 0px;
    top: -3px;
}

.middle_bar_section .top_profile_name [type='text']:focus,
.top_profile_name select:focus {
    --tw-ring-offset-color: #fafafa;
    --tw-ring-color: #fafafa;
}

.video_text_feild .MuiSelect-select.MuiSelect-standard {
    height: 19.13px;
}

.short_flime .label_text {
    padding: 7px 0 0 0;
}

.unfave_icon {
    display: inline-block;
    background: url('./../img/heart_unfav.svg') no-repeat;
    width: 30px;
    height: 30px;
    cursor: pointer;
    vertical-align: top;
    margin: 4px 0 0 0;
}

.unfave_icon_fave_icons {
    float: right;
}

.action_button .MuiButton-contained {
    display: inline-block;
    background: #9B26B6;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
}

.action_button {
    display: inline-block;
}

.action_button .MuiButton-contained:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

.action_button .MuiButton-outlinedPrimary {
    display: inline-block;
    background: #fff;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
    color: #9B26B6;
}

/* .view_profile .MuiButton-contained {
    display: inline-block;
    background: #9B26B6;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
} */

.view_profile {
    display: inline-block;
    margin: 0 0px 0 22px;
}

.top_title {
    /* display: inline-block; */
    vertical-align: middle;
}

/* .top_title .MuiButton-contained:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
} */

button.instagram_login {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    color: #3c76d2;
    text-align: left;
}

.insta_btn img {
    margin-right: 17px;
    width: 25px;
}

.insta_btn {
    display: flex;
    align-items: center;
    margin-left: 25px;
}




/*Landing page css*/
.landing_page_section {
    display: inline-block;
    width: 100%;
    position: relative;
    background: #fff url(../img/landing_page_banner.png) no-repeat top left;
    min-height: 890px;
    background-size: cover;
}

.middle_content_txt {
    margin: 0 auto;
    width: 1110px;
}

.left_home_banner_content strong {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    margin: 0 0 25px 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
}

.landing_page_section .left_home_banner_content h1 {
    line-height: 54px;
}

.landing_page_section .left_home_banner_content p {
    margin: 23px 0 60px 0;
}

.landing_page_section .find_button button {
    height: 76px;
    padding: 0px 80px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    border-radius: 60px;
    letter-spacing: 0em;
    text-align: center;
}

.left_home_banner_content {
    padding: 24% 0 0 0;
}

.left_home_banner_img {
    padding: 22% 0 0 0;
}

.joincasting_section {
    display: inline-block;
    width: 100%;
    position: relative;
    min-height: 890px;
    background: url('../img/scend_banner.svg') no-repeat;
    background-size: cover;
}

.joincasting_section .talent_left_info {
    width: 100%;
    text-align: center;
}

.talent__middle_section {
    margin: 0 auto;
    width: 786px;
}

.talent__middle_section img {
    max-width: 100%;
    display: inline-block;
    margin: 41px 0;
}

.joincasting_section .talent_left_info p {
    text-align: center;
    font-size: 18px;
    color: #ddc2e3;
}

.joincasting_section .talent_left_info h3 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 0 47px 0;
}

.talent__middle_section label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #ddc2e3;
}

.casting_how_it_works {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 170px 0;
    position: relative;
}

.profile {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 75px 0 105px 0;
    position: relative;
}

.profile img {
    display: inline-block;
    max-width: 100%;
}

.profile h3 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    margin: 46px 0 22px 0;
}

.profile p {
    display: inline-block;
    width: 100%;
    color: #44454D;
    font-size: 16px;
    font-weight: 400;
}

.profile:after {
    content: '';
    background: url('../img/arrow.png') no-repeat;
    height: 40px;
    width: 40px;
    position: absolute;
    margin-top: 44px;
    top: 0;
}

.profile.Casting_Calls:after {
    background: none;
}

.start_butt {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.start_butt button {
    display: inline-block;
    background: #9B26B6;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 80px;
    height: 70px;
    padding: 0 60px;
    text-transform: uppercase;
}

.talent_left_active_users_inner h4 {
    display: inline-block;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    color: #101820;
    letter-spacing: 0em;
    text-align: center;
}

.casting_recruiters_list {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background: #fff;
    padding: 170px 0;
    position: relative;
}

.talent_left_active_middle {
    margin: 0 auto;
    width: 1022px;
}

.recu_list {
    /* display: inline-block; */
    width: 100%;
}

.recu_list h3 {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    margin: 0 0 26px 0;
    font-size: 33px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
}

.recu_list p {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 0 0 25px 0;
}

.recu_list button {
    background: #9B26B6;
    height: 60px;
    padding: 0px 60px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    border-radius: 60px;
    margin: 20px 0 0 0;
}

.recu_list.style_right h3 {
    text-align: right;
}

.recu_list.style_right p {
    text-align: right;
}

.recu_list.style_right button {
    float: right;
}

.recu_list button:hover,
.start_butt button:hover,
.find_button button:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

/*terms css start*/
body {
    font-family: 'Poppins', sans-serif !important;
}

.terms_of_use {
    display: inline-block;
    width: 100%;
    background: #e5e5e5;
    padding: 0;
    margin: 0;
}

.terms_of_use h2 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #44454d;
    min-height: 80px;
    vertical-align: middle;
    padding: 22px 0;
}
.terms_of_use h1 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #44454d;
    min-height: 80px;
    vertical-align: middle;
    padding: 22px 0;
}
.cms-top {
    display: inline-block;
    width: 100%;
    background: #f4f4f4;
}

.cms {
    display: inline-block;
    width: 100%;
    margin-top: 3px;
}

.cms ul {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
}

.cms ul li {
    display: inline-block;
    width: 100%;
    padding-top: 50px;
    margin: 0 0 0 -50px;
}

.cms ul li a {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #44454d;
    text-decoration: none;
    padding-left: 40px;
}

.cms ul li a:hover {
    display: inline-block;
    width: 100%;
    color: #ad56c2;
    background: #e5e5e5;
    padding: 15px 0 15px 58px;
    border-radius: 3px;
    margin-left: -24px;
}

.cms-para {
    display: inline-block;
    width: 100%;
    background: #fff;
    margin: 0px 0;
    padding: 37px;
    border-radius: 10px;
}

.paragraph {
    display: inline-block;
    width: 100%;
}

.cms-para h2 {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #44454d;
    margin-top: 15px;
}

.cms-para p {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #44454d;
}

.cms-para p:not(:first-child) {
    margin-top: 18px;

}

.cms-para .paragraph small {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0 37px 0;
    color: #44454d;
}

.cms_sidebar ul li .doc_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/icon_hover.png') no-repeat;
}

.cms_sidebar ul li .compy_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/icon.company.svg') no-repeat;
}

.cms_sidebar ul li:hover .compy_icon {
    background: url('../img/icon.company.png') no-repeat;
}

.cms_sidebar ul li.active a .compy_icon {
    background: url('../img/icon.company.png') no-repeat;
}

.cms_sidebar ul li .contact_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/Contact_icon.png') no-repeat;
}

.cms_sidebar ul li:hover .contact_icon {
    background: url('../img/icon.mail.svg') no-repeat;
}

.cms_sidebar ul li.active a .contact_icon {
    background: url('../img/icon.mail.svg') no-repeat;
}

.cms_sidebar ul li a {
    display: inline-block;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    text-decoration: none;
    width: 100%;
    padding: 12px 13px;
}

.cms_sidebar ul li a:hover {
    background: #E5E5E5;
    color: #9B26B6;
}

.cms_sidebar ul li .pri_icon.doc_icon {
    background: url('../img/icon.policy.png') no-repeat;
}

.cms_sidebar ul li:hover .doc_icon {
    background: url('../img/icon.terms.png') no-repeat;
}


.cms_sidebar ul li:hover .pri_icon.doc_icon {
    background: url('../img/icon.policy_hover.png') no-repeat;
}

.cms_section_new {
    display: inline-block;
    width: 100%;
    margin: 50px 0 0 0;
}

.cms_sidebar ul li.active a {
    background: #E5E5E5;
    color: #9B26B6;
}

.cms_sidebar ul li.active a .doc_icon {
    background: url('../img/icon.terms.png') no-repeat;
}

.cms_sidebar ul li.active a .pri_icon.doc_icon {
    background: url('../img/icon.policy_hover.png') no-repeat;
}

.cms_sidebar ul li a .doc_icon.about_us {
    background: url('../img/about_us.png') 0px -135px no-repeat;
}

.cms_sidebar ul li a .contactus.doc_icon {
    background: url('../img/about_us.png') 0px -179px no-repeat;
}

.cms_sidebar ul li.active .doc_icon.about_us {
    background: url('../img/about_us.png') -53px -135px no-repeat;
}

.cms_sidebar ul li:hover .contactus.doc_icon {
    background: url('../img/about_us.png') -53px -179px no-repeat;
}

.how_it_works_new .MuiTabs-flexContainer {
    width: 100%;
}

.how_it_works_new .MuiTab-textColorPrimary {
    width: 50%;
    max-width: 50%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    text-transform: inherit;
}

.botom_tabs {
    display: inline-block;
    width: 100%;
    background: #fff;
    margin: 0px 0;
    padding: 37px;
    background: #FFFFFF;
    border-radius: 0px 0px 10px 10px;
}

.how_it_works_new .MuiTab-textColorPrimary.Mui-selected {
    color: #9b26b6;
}

.cms_para .cms_icon {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.cms_para .cms_icon h3 {
    display: inline-block;
    width: 100%;
    font-size: 10px;
    font-weight: 600;
    color: #44454D;
    line-height: 17px;
}

.cms_para .cms_icon p {
    display: inline-block;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    color: #44454D;
    margin: 30px 0;
}

.cms_para .cms_icon .cms_paragraph p {
    display: inline-block;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    color: #44454D;
    margin: 42px 0;
}

.outer_bg_section_tab_common_section .MuiInputBase-colorPrimary.MuiInputBase-formControl {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: inherit;
    letter-spacing: 0px;

    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

button.oauth_btn span {
    padding: 0 !important;
    margin-right: 15px;
}

.Email_pop_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    width: 100%;
    color: #9B26B6;
}

.Email_pop_label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;
}

.Email_pop_button .MuiButton-contained:hover {
    background: #fff;
    color: #9B26B6;
}

.Email_pop_button .MuiButton-contained:focus-visible {
    border: none;
}

.image_items_size {
    min-height: 250px;
    min-width: 200px;
}

.image_items_min_size {
    min-height: 150px;
    min-width: 100px;
}

.images_uploader_left {
    padding-left: 1px !important;
}

.images_uploader_right {
    padding-right: 1px !important;
}

.forms_terms {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;
}





.display_none {
    display: none !important;
}

.loading>.css-hz1bth-MuiDialog-container>.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    /* background: none !important; */
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important
}

.spinner-container {
    /* position: absolute; */
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
    z-index: 400px
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #9B26B6;
    /* Light grey */
    border-top: 5px solid #f3f3f3;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.circle {
    position: fixed;
    width: 100%;
    left: 92%;
    bottom: 40px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: green;
}

.circle img {
    transform: rotate(180deg);
    border-radius: 50%;
    background-color: #9B26B6;
    width: 2.5rem;
}

.infoText {
    display: inline-block;
    color: #9B26B6;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    width: 100%;
    text-align: start;
}

.image_loader .MuiDialog-container .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
}

.info_text_disable {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #9B26B6;
}

.iiz__img {
    max-height: 55vh;
}

/* for media css */
.sm_editIcon {
    display: none;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    color: #9B26B6;
}

.Moblie_edit_img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.Moblie_edit_img>img {
    height: 100% !important;
}

#PhotoGrallery .slick-next {
    right: 17px;
    top: 46%;
}

#PhotoGrallery .slick-prev {
    left: 0px;
    top: 46%;
    z-index: 2;
}

#PastWorks .slick-next,
#EducationandSkills .slick-next {
    right: 38px;
    top: 38%;
}

#PastWorks .slick-prev,
#EducationandSkills .slick-prev {
    left: 3px;
    top: 38%;
    z-index: 2;
}

#PastWorks .MuiTabs-scrollButtons {
    color: #9b26b6;
}
#PastWorks .MuiTabs-scrollButtons svg{
    width: 85%;
    height: 100%;
}

.slick-next:before,
.slick-prev:before {
    font-size: 35px;
}

#edit_imageUpload .image_items_size {
    min-height: 185px;
    min-width: 75px;
}


.loader_height {
    height: 78vh;
}

.textFeild [type='search']:focus,
.textFeild [type='text']:focus,
.textFeild [type='email']:focus,
.textFeild [type='password']:focus {
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
    border-color: #ffffff;
}

.menu_items {
    position: relative;
}

.menu_items {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    text-transform: capitalize;
    color: #44454D;
}

.menu_items.active_item {
    color: #9B26B6;
}

.active_menu_bar {
    position: absolute;
    border: 3px solid #9B26B6;
    background-color: #9B26B6;
    /* width: 100%; */
    bottom: -19px;
    left: -10px;
    right: -10px;

}

.modal-open {
    /* overflow: scroll !important; */
    overflow: auto !important;
    padding-right: 0px !important;
}


.icons_list {
    display: inline-block;
    vertical-align: top;
    width: 30px;
}

.icons_list .tick_img {
    height: 25px;

}

.recruiter_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #44454D;
    word-break: break-all;

}

.submit_notVaild {
    opacity: 0.5;
}

.form_err {
    font-family: 'Poppins';
    font-size: 12px;
    color: #FF0000 !important;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.error .MuiInput-underline:before {
    border-bottom-color: #FF0000 !important;
}

/* .summary_list_page p.dropdown_placeholder_line{
    color: #44454D !important;
    font-weight: 400;
    font-size: 1rem;
} */
#summary_openingsDropdown .custom_dropdown {
    border-bottom: 1px solid #979797;
}

p.dropdown_placeholder_line,
label#disable-close-on-select-label-list {
    font-size: 14px;
    color: #E5E5E5 !important;
    position: absolute;
    font-weight: 400;
    line-height: 2.1;

}


.profileSetting p {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #44454d;
}

.fontStyle_otp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #44454D;

}

.errText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #FF0000;
    letter-spacing: 0.03333em !important;
}


.otp_error_value input,
.otp_error_value select,
.otp_error_value .MuiInputBase-root:before,
.otp_error_value input,
.otp_error_value select,
.otp_error_value .MuiInputBase-root::after {
    border-color: #FF0000 !important;
}

.emailErrText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FF0000;

}
.character_color {
    color: #FF0000;
}

.not__vaild_img {
    /* opacity: 0.3; */
    filter: opacity(50%);
}

.errMsgcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    width: 93%;
    max-width: 360px;
    border-radius: 5px;
}

.linkText {
    color: #9B26B6;
    font-weight: 700;
    cursor: pointer;
}

.headerText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101820;

}

.authContainer {
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 11px 11px 11px 11px;
    width: 93%;
    /* max-width: 360px; */
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #44454D;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
}

.applicationStatus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    white-space: nowrap;
}
.applicationStatus_sent{
    color: #979797;
}
.applicationStatus_seen{
    color: #12C7E0;
}

.input_textColor .MuiInput-root   [type='text'] {
    color: #9b26b6;
    font-weight: 700;
    cursor: pointer !important;
}
.talent_name {
        color: #9B26B6 !important;
        font-weight: 500;
    }

.auth_btn:focus-visible {
    outline: none !important;
    border: 2px solid;
}

/*  */

#edit_imageUpload .removePlaceHolderBG {
    background: transparent !important;

}

.chartAtLimit {
    font-family: 'Poppins';
font-style: normal;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.charColor {
    color: #44454D;
}

#hideInput input{
    width: 0;
}
#hideInput .MuiInputBase-root.MuiInput-root.MuiInput-underline{
    justify-content: space-between;
}
#cell_color {
    color: #9B26B6 !important;
}

.linkFont{
    font-family: 'Poppins' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
}

.first_alert__title {
    text-align: center;
    color: #9B26B6;
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    padding-top: 40px !important;
  }
  .ErrorPageHeight{
    height: 90vh;
  }

  .headerTags {
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


/* Text Black */

color: #101820 !important;
  }

  .dashedSpace {
    width:100%;
    height:1px;
    background-image:linear-gradient(to right, #E5E5E5 0%, #E5E5E5 50%, transparent 50%);
    background-size:28px 1px;
    background-repeat:repeat-x;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    box-shadow:none !important
}
.form_labelhomebxcalendr .MuiInputBase-colorPrimary{font-size:14px;}
.two-column{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
  .layout_min_height{
    min-height: calc(100vh - 350px);
  }

  *:focus-visible {
    outline: transparent;
  }

  .header_top_section {
    position:sticky ;
    /* left: 0px; */
    top: 0px;
    width: 100%;
    z-index: 1400;
 
  }
  .min_header_height{
    /* min-height: 73px; */
  }
  .gap_chips{
    gap:20px
  }
  .sm_margin_actionAree{
    margin: 0 20px 20px 20px;
  }
  .customLabel span.MuiTypography-root{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #44454D;
    margin-left: 10px;
  }
.headerTags:hover {
    color: #9B26B6;
}
.posterHeading {
    color: #9B26B6;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
}

.MuiDialog-root ~ .MuiAutocomplete-popper {
    z-index: 1400 !important;
}
.MuiFormHelperText-root.Mui-error {
 color:#FF0000 !important;
}

.audition_btn {
    color:#9B26B6;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uplode_file.float_rightbutton .MuiButton-root{float:right}
.userprofileadd{display:flex; width:132px; min-height:160px;border-radius:8px; background:#D9D9D9;margin-top: 30px;    align-items: center;
    justify-content: center;}
.userprofileadd_infomations b{display:inline-block; width:100%; font-size:12px; font-weight:600px; color:#44454D;}
.userprofileadd_infomations p{display:inline-block; width:100%; font-size:12px; font-weight:400px; color:#44454D; padding: 0 20px 0 0px;}
.errorvalidation{min-height: 103px;}
.userprofileadd_infomations .uplode_file.float_rightbutton .MuiButton-root{float:left;}
.userprofileadd_infomations{    margin-top:39px;}
.addImg{display:inline-block; width:100%; text-align:center;}
.addImg img{display:inline-block;}
.left_home_banner_contentnew1.showinmobile{display:none;}
.Moblie_edit_img svg{    font-size: 19px;}
.newmobileiconcolor .outline-none svg{    color: #7a1393;}
.dropdownArea.commonfindjobsection{width:650px;}
.PopupSection.widthincress{width:300px;}

.showmore{color: #9b26b6; cursor: pointer;}
.form_labelhomebxcalendr .MuiFormControl-root{display:inline-block; width:100%;}
.form_labelhomebxcalendr .MuiInputBase-colorPrimary{width:100%;    background: #fff;}
.display_left li{display:inline-block; padding:0 0px 0 15px;}
.withoutpadding li{display:inline-block; padding:0 0px 0 15px;}
.padding_left10{padding-left:6px!important;}
.uplode_file.float_rightbutton.newbiuttons{margin:10px 0 0 0;}
.company_logo img{border-radius: 8px;}
.bm-cross-button .span{display:none;}
.bm-item.topmobilelogo{position: absolute;top: 14px;}
.bm-item-list ul{margin-top:20px}
.bm-item-list ul li{margin-top:0px; display:inline-block; width:100%; padding:10px 0;}
.bm-burger-button{display:none;}
.mobilenavemenu{display:none;}
.mobilemenuhidden .MuiIconButton-sizeSmall{margin-left:0px;}
.container_spacing{margin: 0 15px !important;}
.middle_bar_section .hiddencolorpage #custom_button{    background: #f4f4f4!important;
    border-color: #f4f4f4!important;box-shadow:none;    padding-left: 0px; cursor:default; color:#44454d!important;}
.middle_bar_section .hiddencolorpage #custom_button:hover{ background: #f4f4f4!important;}
.value .MuiInput-underline.Mui-error:before, .Mui-error.MuiInput-underline:before {border-bottom: 1px solid red!important;}
.freeze_text_color span{color: #9f9f9f;}
.Homepagereamovepadding .display_left {margin: 0 10px 0 0;}
.beforetalenterhomepage.talenterhomepage .newmobileiconcolor {right:44px;top: 10px;}
.beforetalenterhomepage .newmobileiconcolor {right:15px; top:13px;}



.unlock_list_page .filter_area {
    background: #E5E5E5;
    display: flex;
    padding: 0  20px;
    margin-bottom: 20px;
    /* justify-content: space-between; */
    align-items: center;
}
.unlock_list_page .filterOption, .summary_list_page .filterOption {
    min-height: 60px;
    border-bottom: 3px solid transparent;
    display: flex;    
    align-items: center;
    cursor: pointer;
}
.unlock_list_page .filterOption {
    justify-content: center;
}
.unlock_list_page .filterOption > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #979797;
}

.unlock_list_page .filterOption.active {border-bottom-color: #9b26b6;}
.unlock_list_page .filterOption.active > p {color: #44454D;}
.mobile_view_title{position: relative; /* top: 20px; */}

.unlock_list_page .filterOption.active {border-bottom-color: #9b26b6;}
.unlock_list_page .filterOption.active > p {color: #44454D;}
.common_footer_section .mobileopen{display:none;}
.value.newwidth{width:250px;}
.MuiDialog-scrollPaper .commonpaddingnew{padding:0 5rem;}
.rsmobileopen{display:none;}
.MuiDialogContent-root.addpadding{padding:0 3rem;}
.widthchangenew{min-width:200px;}
.flex-wrap.with100 .faverlistnew_mobile{display:none;}
.tablelistcontent{width:100%;text-align: left;}
.tablelistcontent thead th{padding:0 10px;}
.tablelistcontent tbody td{padding:0 10px;}
.Load_search_list .diaalogpadding{padding:0 5rem;}
#ShareProjectPopupdata .MuiSlider-root.Changethesliecolor{color:#9B26B6;}
.Profilechangebutton .RemoveBG{    background: white;}
.Profilechangebutton .sec_button#custom_button{    background: #fff;
    color: #44454D;    border: 1px solid #E5E5E5;   font-weight: 600;    font-size: 16px;text-align: center;margin: 0 17px;}
.reducethepopupBox{width: 310px!important;}
.img-circle{    padding: 25px 0 0;}
.commonUP small{display:inline-block; width:100%; text-align:left; font-size:14px; font-weight:600; color:#333;    margin: 0 0 9px 0;}
.commonUP [type=file] {display:inline-block; width:100%; text-align:center;    border: 1px solid #e5e5e5; cursor:pointer;
padding: 5px 5px;border-radius: 5px; font-size:13px;}
.commonUP [type=file]:focus{outline:none;}
.UPprofileIMG .MuiDialog-paper{width: auto;}
.bm-overlay{top:0px; left:0px;}

.w-full.bg-white.shadow .bm-menu-wrap{top:0px; left:0px;}
.NameTagChange{display:inline-block; width:100%;}
.shortTextIcons{display:inline-block;float: right;    vertical-align: top;
margin: 0px;padding: 0px;}
.shortlistcommongnew .ListCard p .shortText{ -o-text-overflow: ellipsis;   /* Opera */
text-overflow:    ellipsis;   /* IE, Safari (WebKit) */    position: relative;
top: 5px;overflow:hidden;/* don't show excess chars */white-space:nowrap;display: inline-block;/*force single line */width:113px;}
.gap-3.mobilepaddingremove{padding:15px 2.3rem 25px;}
.fourbnottext{font-size:22px; color:#44454D; text-align:center;line-height:30px; font-weight:600;}
.MuiTextField-root.widthfullwidth{width:100%;}
.uplodebuttonsnew .uplode_file #custom_button.MuiButton-root.white{width:100%;}
.widthfullwidth #standard-basic{padding: 12px 0 5px;}
.UserprofileImage .uplode_file #custom_button{width:100%;}

#ShareProjectPopup span.phot_close__icon {padding-left: 5px;margin: 5px;}
#ShareProjectPopup span.phot_close__icon {position: absolute;top: 0;right: 0;width: 35px;height: 35px;background: #aa17b3;
text-align: center;color: #fff;display: flex;cursor: pointer;border-radius: 50%;justify-content: center;
}
#ShareProjectPopup span.phot_close__icon svg{user-select: none;
width: 1em;
height: 1em;
display: inline-block;
fill: currentcolor;
flex-shrink: 0;
transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
font-size: 1.5rem;position: relative;
top: 5px;
left: -2px;}

.UserprofileImage #custom_button.MuiButton-root.white:hover {background: #ffffff;color: #aa17b3;}
.UserprofileImage .form_err{text-align:left;}
.PBL{display:inline-block; width:100%; padding-bottom:10px;}
.screenRange{display:inline-block; width:100%; padding:0 15px;}
.animations_New svg{width:968px!important;transform:translate3d(-316px,-34px,0)!important;}
.widthChange svg{width:770px!important;transform:translate3d(0px,1px,0)!important;}

.summary_filter{
    position: relative;
}
.summary_filter p{
    font-size: 14px !important;
}
.summary_filter .summary_text {
    font-weight: 600;
    color: #979797;
}
.summary_filter .active_menu_bar {
    position: absolute;
    border: 2px solid #9B26B6;
    background-color: #9B26B6;
    font-weight: 600;
    /* width: 5%; */
    bottom: -12px;
    left: 0;
    right: 0;
}
.summary_list_page .custom_dropdown div#custom_select, .MuiInputBase-input, .summary_list_page p.dropdown_placeholder_line {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 21px; */
    color: #44454D !important;
    width:180px
}
.new_addOuter .MuiInputBase-input.MuiOutlinedInput-input{display: none;}
.new_addOuter button svg {margin-top: 25px !important;}
.eventIcon_icon.newclndr .MuiInputBase-input.MuiOutlinedInput-input{padding: 0px;}
.eventIcon_icon.newclndr fieldset.MuiOutlinedInput-notchedOutline{border-color:none;    border-color: rgb(255 255 255 / 23%);}
.eventIcon_icon.newclndr button{padding: 6px 3px 0;}

.eventIcon_icon.newclndr button svg{    font-size: 26px;color: #9b26b6!important;}
.unlock_talent {
    text-align: center;
    margin: 20px;
}
.unlock_talent .unlock_talent_content h3 {
    color: #9B26B6;
    font-weight: 600;
    font-size: 24px;
    margin-top: 28px;
}
.unlock_talent_content p {
    color: #44454D;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin: 15px 0 10px 0;
}
.unlock_talent_content p span{color:#000000;font-size:18px;font-weight:600}
.unlocak_talant_modal {
    background: #fff;
    max-width: 860px;
    overflow: auto;
    height: 80vh;
    margin-top: 32px;
   
}
.unlock_talent_content button{background: #44454D;color:#FFFFFF;font-weight:600;width:295px;height:42px;border-radius: 5px;padding: 8px 7px 8px 0px;margin:10px 0 14px 0;
    /* cursor: pointer; */
    font-size: 14px; cursor:default}
    .unlock_talent_content label{display: inline-block;color:#44454D;font-size:18px;font-weight:400}

.MuiGrid-root.double_grid .MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin{    background: #fff;
    /* display: none; */
    color: #222;}

    /* .double_grid_outer_box button.Mui-selected.MuiPickersDay-dayWithMargin {
        color: #fff !important;
        font-weight: 600;
    } */
    .checking_part {
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        margin-bottom: 40px;
        margin-left: 33px;
    }
    /* .modal_calender{padding: 0 38px 23px 38px;} */
    .modal_calender_outerBox p {
        text-align: center;
        color: #44454D;
        font-size: 14px;
        font-size: 400;
        font-weight: 400;
        
    }
    .modal_calender_outerBox .checking_part .checking_cancel{
        border:1px solid #E5E5E5;
        background: #E5E5E5;
        color:#979797;
        font-size: 16px;
        font-weight: 600;padding: 8px 30px 8px 30px;
        width: 200px;
        height: 42px;
        border-radius: 5px;
    }
    .modal_calender_outerBox .checking_part .checking_avail {
        border: 1px solid #E5E5E5;
        background: #9B26B6;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        padding: 8px 21px 8px 30px;
        width: 200px;
        height: 42px;
        margin-right: 20px;
        border-radius: 5px;
    }
     svg.closed_icon1 {color:#9B26B6;float: right;}
    .unlocak_talant_modal header{display: none;}
.closed_icon {float:right;color:#9B26B6}
.hover_close_icon.MuiAutocomplete-hasClearIcon .MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator:hover{display:none}
.MuiAutocomplete-hasClearIcon.hover_close_icon .MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator{display: none;}


.user_namechat.MuiTypography-root{font-weight:600;font-size: 12px;color:#44454D;line-height: 20px;}
.chat_righttime.MuiTypography-root{font-weight:500;font-size: 10px;color:#979797;line-height: 16px;}
.badge_chat.MuiBadge-root  .MuiBadge-badge {background:rgba(155, 38, 182, 0.30);font-size: 9px;font-weight: 600;line-height: 14px;
    text-align: center;color:#9B26B6;}
    .modal_calender_outerBox span.MuiTypography-caption.MuiDayCalendar-weekDayLabel {
        font-weight: 600;
        color: #979797;
        font-size: 16px;
    }



.double_grid_outer_box .MuiPickersDay-dayWithMargin{border:none !important}
.double_grid_outer_box_talent .MuiPickersDay-dayWithMargin{border:none !important}

/* 
.double_grid_outer_box .MuiPickersCalendarHeader-root {
    padding-left: 0px !important;
    padding-right: 21px !important;
} */
/* .double_grid_outer_box .MuiPickersCalendarHeader-root {margin:3px 8px !important;padding-left: 0 !important;padding-right: 0 !important;} */
/* .double_grid_outer_box .MuiPickersCalendarHeader-root {
 
    padding-left: 33px !important;
    padding-right: 22px !important;
} */
.double_grid_outer_box .MuiPickersCalendarHeader-root {
    padding-left: 13px !important;
    padding-right: 0 !important;
}
.double_grid_outer_box .MuiDateCalendar-root.fix-data_outer{width:273px !important}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-root {
    margin: 25px 6px 5px 6px;
    justify-content: center !important;
    display: flex;
}
.double_grid_outer_box_talent .MuiDateCalendar-root.fix-data_outer{width:273px !important}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-label {    color: #44454D;
    font-weight: 600;}
    .double_grid_outer_box_talent .MuiPickersArrowSwitcher-button, .MuiPickersArrowSwitcher-button,.MuiPickersCalendarHeader-switchViewButton{color:#00000099 !important}
.unlocak_talant_dialogue .MuiPaper-elevation.MuiPaper-rounded{max-width:850px;width:100%}
.unlocak_talant_dialogue .MuiDialogContent-root {padding:6px 0 0 0}
.res_use{display: none;}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-labelContainer {
    padding: 0 31px 0 0;
    margin: 0!important;
}
.profileDropDownButton_outer{display: none;}
.login-buttonOuter{position:"relative"}
.img_flex{margin-right: 0 !important;}
.res_newmobileiconcolor{display: none;}
.id_profImg{padding-left:0 !important}
.res_newmobileiconcolorOuter{display:none}
/* .Latest_X{padding:0 !important} */
 .Latest_X svg{font-size:15px;color:#fff;    position: absolute;
    top: 8px;
}

 .Latest_X {
    background: black;
    border-radius: 100px;
    padding: 12px 11px 0 7px !important;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
}
.message_TotalCount span {
    background: #e1bee9;
    border-radius: 100%;
    min-width: 20px;
    height: 17px;
    font-size: 10px;
    padding: 3px;
    margin-top: 3px;
    margin-left: 2px;
    line-height: 14px;
    text-align: center;
    color: #9B26B6;
    display: inline-block;
    font-weight: 600;
}
.MuiFormControl-root.refereral_codes {
    width: 93%;
    background: #fff;
    margin-bottom: 12px;
    border-radius: 4px;
}
.MuiFormControl-root.refereral_codes .MuiInputBase-input::placeholder{color:#494949;font-size: 14px;font-weight: 600;font-style: italic;}
.MuiFormControl-root.refereral_codes .MuiInputBase-root.MuiOutlinedInput-root{padding:12px 14px}
.MuiFormControl-root.refereral_codes .MuiOutlinedInput-notchedOutline{border:1px solid #E5E5E5}
/* .MuiFormControl-root.refereral_codes .MuiInputBase-root.MuiOutlinedInput-root:hover{border: none;} */
.MuiFormControl-root.refereral_codes .Mui-focused .MuiOutlinedInput-notchedOutline{border:2px solid #9B26B6;outline: 0;box-shadow: none;}
.MuiFormControl-root.refereral_codes .MuiFormHelperText-root.Mui-error{
    background: #F4F4F4 !important;
    /* padding: 0; */
    margin: 0;
    padding-top: 10px;
}
/* .block_userLists{height:80px;overflow: scroll;} */


