#custom_button.MuiButton-root {
    background: #9B26B6;
    color: #fff;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 2px 20px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 38px;
    text-transform: inherit;
    margin: 0;
    /*  */
    position: relative;
}

#custom_button.MuiButton-root:hover {
    background: #fff;
    color: #9B26B6;
}


/* button with icon  */

#custom_button.MuiButton-root:hover .MuiButton-startIcon .MuiSvgIcon-root {
    color: #9B26B6;
}

#custom_backbutton.MuiButton-root {
    background: #fff;
    color: #44454D;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 2px 20px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 38px;
    text-transform: inherit;
    margin: 0;
    /*  */
    position: relative;
}

#custom_backbutton.MuiButton-root:hover {
    background: #fff;
    color: #44454D;
}

#custom_button.MuiButton-root.white.noHover:hover {
    background: #fff !important;
    color: #9B26B6 !important;
}

#custom_button.MuiButton-root.noHover:hover {
    background: #9B26B6 !important;
    color: #fff !important;
}

#custom_button.MuiButton-root.white {
    background: #fff;
    color: #9B26B6;
}

#custom_button.MuiButton-root.white:hover {
    background: #9B26B6;
    color: #fff;
}

#custom_button.MuiButton-root.outlinegray,
#custom_button.MuiButton-root.outlinegray:hover {
    background: #fff;
    color: #979797;
    border: 1px solid #979797;
}

#custom_button.MuiButton-root.gray {
    background: #44454D;
    color: #fff;
    border: 1px solid #44454D;
}

#custom_button.MuiButton-root.gray:hover {
    background: #44454D;
    color: #fff;
}

#custom_button.MuiButton-root.deleteBtn {
    background: #fff;
    color: #FF0000;
    border: 1px solid #FF0000;
}

#custom_button.MuiButton-root.deleteBtn:hover {
    background: #fff;
    color: #FF0000;
}

#custom_button.MuiButton-root.disableBtn {
    border-color: #E5E5E5;
    color: #9f9f9f;
    background: #E5E5E5;
    margin-right: 20px;

}
.Mui-disabled.marginbottom_outer {
    margin-right: 0 !important;
}
#custom_button.MuiButton-root.verify_button.disableBtn{
margin-right: 0 !important;
}

#custom_button.MuiButton-root.disableBtn:hover {
    border-color: #E5E5E5;
    color: #9f9f9f t;
    background: #E5E5E5;
}

.displayNone {
    opacity: 0;
}

.btn_container {
    display: flex;
    justify-content: center;
}

.fullWidth.MuiButton-root {
    width: 100% !important;
    padding: 8px 0;
}

#customClearButton.MuiButton-root {
    background: #fff;
    color: #979797;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 2px 20px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 38px;
    text-transform: inherit;
    margin: 0;
    /*  */
    position: relative;
}

.btn_container_white .MuiButton-root {
    background: #fff;
    color: #9B26B6;
    width: fit-content;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 2px 20px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 38px;
    text-transform: inherit;
    margin: 0 0 0 0;
}

.btn_container_white .MuiButton-root:hover {
    background: #9B26B6;
    color: #fff;
}