/* Position and sizing of burger button */

.bm-burger-button {
    position: fixed;
    width: 25px;
    height: 25px;
    right: 15px;
    top: 21px;
}


/* Color/shape of burger icon bars */

.bm-burger-bars {
    background: #373a47;
}


/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
    background: #a90000;
}


/* Position and sizing of clickable cross button */

.bm-cross-button {
    height: 24px;
    width: 24px;
}


/* Color/shape of close button cross */

.bm-cross {
    background: #bdc3c7;
}


/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}


/* General sidebar styles */

.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    padding: 26px 5px;
}


/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
    fill: #373a47;
}


/* Wrapper for item list */

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}


/* Styling of overlay */

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}


/* Individual item */

.bm-item {
    display: inline-block;
    color: #000;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 18px;
    font-weight: 500;
}

.bm-item:hover {
    color: #9B26B6;
}


/* @media screen and (max-width: 55rem) {
    .bm-burger-button {
      display: none;
    }
  } */

.bm-cross-button #react-burger-cross-btn {
    background: url('../assets/img/Icon-close.svg') 0px 0px no-repeat !important;
}

.bm-burger-button #react-burger-menu-btn {
    background: url('../assets/img/Mobilemenuicon.svg') 0px 0px no-repeat !important;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross {
    display: none;
}