.notification_container .contentText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #44454D;

}

.category_color {
  color: #e2dfdf !important;
}

.profile_setpara {
  font-size: 16px !important;
  font-weight: 400;
  color: #44454D;
  font-family: 'Poppins';

}

.profile_set {
  display: inline-block;
  width: 100%;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 0px;
  letter-spacing: 0em;
  text-align: left;
  color: #44454D;
}

.bottom_space_line {
  display: inline-block;
  width: 100%;
  min-height: 30px;
}

.profile_color {
  color: #9B26B6 !important;
  font-size: 19px !important;
  font-weight: 550;


}

.thanksText {
  color: #9B26B6 !important;
  font-size: 14px !important;
  font-weight: 550;


}

.margin_left {
  margin: -2px;
}

.profile_title {
  display: inline-block;
  width: 100%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 0px;
  letter-spacing: 0em;
  text-align: left;
  color: #44454D;
  margin-left: 1px;

}

.profile_para {
  display: inline-block;
  line-height: 20px !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #44454D;

  width: 100% !important;
}

/* .btn_container_btn .MuiButton-root {
     background: #9B26B6;
     color: #fff;
     width: 150px;
     border: 1px solid #9B26B6;
     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
     border-radius: 5px;
     font-family: 'Poppins';
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
     padding: 4px 20px;
     letter-spacing: 0em;
     text-align: center;
     min-height: 38px;
     text-transform: inherit;
     margin: 0 0 0 0;
    }
    
    .btn_container_btn .MuiButton-root:hover {
     background: #fff;
     color: #9B26B6;
    } */
/* .profile_set p {
     display: inline-block;
     width: 100%;
     font-family: Poppins;
     font-size: 14px;
     font-weight: 400;
     line-height: 0px;
     letter-spacing: 0em;
     text-align: left;
     color: #44454D;
     margin-left: 1px;
   
   } */

.switch_design_set {
  display: inline-block;
  margin: 18px 0 0 11px;
  margin-left: 1px;
}

.switch_design_right {
  display: inline-block;
  margin: 31px 0 0 27px;
}

.switch_design_left {
  display: inline-block;
  margin: 31px 0 0 8px;
}

.switch_designLeft {
  display: inline-block;
  margin: 31px 0 0 1px;
}

.switch_design_profile {
  width: 100%;
}

.switch_design_set .MuiTypography-root {
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  font-family: 'Poppins';
  text-align: left;
  padding: 0 0 0 11px;
}

.personal_data_section .switch_design_set .MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(19px);
  -moz-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px)
}

/* .btn_container_white_btn .MuiButton-root {
     background: #fff;
     color: #eb1808;
    
     width: fit-content;
     border: 1px solid #eb1808;
     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
     border-radius: 5px;
     font-family: 'Poppins';
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
     padding: 2px 20px;
     letter-spacing: 0em;
     text-align: center;
     min-height: 38px;
     text-transform: inherit;
     margin: 0 0 0 0;
    
    }
    
    .btn_container_white_btn .MuiButton-root:hover {
     background: #eb1808;
     color: #fff;
    }
     */


.notification_container .time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #979797;
}

.BoldText {
  font-weight: 600 !important;
}

/* notification dropdown  */
#notification_badge>span {
  top: 2px;
  right: 4px;
}

#account-menu {
  z-index: 1400;
  /* max-width: 925px; */
}


#account-menu li.MuiMenuItem-root {
  white-space: break-spaces;
  max-width: 500px;
  align-items: flex-start;
  
}

/* subscription page-------------------------------- */

.table_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  text-transform: capitalize;
  color: #44454D;
}
.table_price{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 27px;
text-align: center;
text-transform: capitalize;

/* Text Grey */

color: #44454D;
}
.plan_title {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
text-align: center;
text-transform: uppercase;

/* Maroon */

color: #9B26B6;
}
.MuiLoadingButton-root ,.MuiLoadingButton-root.Mui-disabled{

font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 14px !important;
line-height: 21px !important;
text-align: center !important;
color: #FFFFFF !important;
background: #979797 !important;
}

.MuiButton-outlined.MuiLoadingButton-root, .MuiButton-outlined.MuiLoadingButton-root.Mui-disabled{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  
  /* Field Grey */
  
  color: #979797 !important;
  background: #fff !important;
}

.blurContainer {
  filter: blur(2px);
}
.custom_tableBG {
  background: #FBF7FC;
}

.table_plan_error {
  position: absolute;
  right: 9.56%;
  top: 12%;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  
  /* Error */
  
  color: #FF0000;
  
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.featureText {
  font-family: 'Poppins';
font-style: italic;
font-weight: 400;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
text-align: right;

/* Text Grey */

color: #44454D;
}


.modal_popup {
  /* max-width: 1100px; */
  background: #fff;
  padding: 20px;
  /* height: 390px; */
  width: 700px;
}
.modal_popup .modal_range{
  padding:17px 13px 0 13px 
}
.modal_popup header{
  display: none;
}

.talent_module_modal{background-color: #fff;border-radius: 7px;}

.bring_talent{    margin-bottom: 25px}
.bring_talent h2 {
    text-align: center;
    color: #9B26B6;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins';
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: center;
}
.bring_talent h2 svg {
    font-size: 18px !important;
}


.modal_body p {
  color: #000000;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 12.5px;
  margin-top: 40px;
  line-height: 18px;
}

.modal_body .email_template .MuiFormControl-root.MuiTextField-root {width: 100%;}

.modal_body .email_template {margin-top: 15px;}

.select_button{
  display: flex;
  justify-content: space-between;
  /* margin-top: 16px; */
  margin: 20px 0;
}
.select_button .show_but {
  background: #9B26B6;
  color:#fff;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;border:1px solid #9B26B6;
  margin-right: 20px;
}
.select_button .show_hovor_button.MuiButtonBase-root:hover {
  background: #fff;
  color:#9B26B6;
   
}
.select_button .cleat_but{
  background: none;
  color:#9B26B6;
  font-weight: 600;
  border: 1px solid #9B26B6;
  text-transform: capitalize;
  cursor: pointer;
}

.modal_select .cleat_but{
  background: #E5E5E5;
  border-radius: 5px;
  color: #979797;
  font-weight: 600;
  font-size: 16px;
  /* padding: 10px 28px; */
  padding: 6px 33px;
  border-color: #E5E5E5 !important;
}
@media only screen and (min-width: 1536px) {
  .table_plan_error{
    right: 12.56%;
   }
  
  }
  .part_size {
    /* margin: 20px 70px; */
    margin: 0 70px 19px 70px;
}
  .message_para{font-size:15px !important;color:#44454D !important;line-height: 27px !important;    margin-top: 0 !important;text-align: center !important;}
  .message_para a{color:#9B26B6;font-weight: 600;}
  .message_para_outer{padding:0 120px}
  .select_button_1{
    display: flex;
    justify-content: center !important;
    /* margin-top: 16px; */
    margin: 20px 0;
  }
 .message_para a::before {
    content: 'Recruiter';
    color: #44454D !important;
    font-weight: 400;
    margin-right: 5px;
}
.message_para strong{font-size: 14px;}
.show_butBlack:hover{border:1px solid black !important}