@media only screen and (max-width: 1550px) {
  .padding_leftright .ImageArea {
    min-height: 204px;
  }
}
@media only screen and (max-width: 1440px) {
  .photo_action_contianer {
    min-width: 157px;
  }
  .form_labelhomebxcalendr .MuiInputBase-colorPrimary {
    font-size: 14px;
  }
  .photo_action_contianer h6 {
    padding: 2px 16px;
  }
  .form_labelhomebxcalendr .MuiInputBase-colorPrimary {
    height: 42px;
  }
  .landing_page_section {
    min-height: 760px;
  }
  .animations_New svg {
    width: 850px !important;
    transform: translate3d(-285px, -23px, 0) !important;
  }
  .widthChange svg {
    width: 660px !important;
    transform: translate3d(0px, 1px, 0) !important;
  }

  .left_home_banner_content h1 {
    font-size: 41px;
    line-height: 48px;
  }

  .landing_page_section .find_button button {
    height: 67px;
    padding: 0px 20px;
    font-size: 21px;
  }

  .joincasting_section {
    min-height: 760px;
  }

  .talent_left_info {
    padding: 10% 0 0 0;
  }

  .joincasting_section .talent_left_info h3 {
    margin: 0px 0 27px 0;
    font-size: 25px;
  }

  .casting_how_it_works {
    padding: 118px 0;
  }

  .top_tobottom {
    margin: 0 0 20px 0;
  }

  .casting_recruiters_list {
    padding: 113px 0;
  }
}

@media only screen and (max-width: 1366px) {
  .photo_action_contianer {
    min-width: 150px !important;
  }
  .widthChange svg {
    width: 700px !important;
    transform: translate3d(-88px, 1px, 0) !important;
  }
  .mb-28 {
    margin-bottom: 1rem;
  }
  .photo_action_contianer h6 {
    padding: 0px 10px !important;
  }

  .left_home_banner_img {
    padding: 10% 0 0 0;
  }

  .left_home_banner_content {
    padding: 10% 0 0 0;
  }

  .landing_page_section {
    min-height: 708px;
  }

  .talent__middle_section img {
    max-width: 13%;
  }
  .profile {
    margin: 42px 0 63px 0;
  }
  .profile img {
    max-width: 87px;
  }
  .profile:after {
    background-size: 25px;
  }

  .joincasting_section {
    min-height: 708px;
  }
}

@media only screen and (max-width: 1280px) {
  .top_profile_name {
    width: 100%;
  }
  .talenter_profile_middle {
    width: 100%;
  }
  .px-24.padding_REduce {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1080px) {
  .saveNext {
    padding: 0 6px;
  }
  .widthChange svg {
    width: 100% !important;
    transform: translate3d(-0px, 1px, 0) !important;
  }
  .top_profile_section_bot_list.pb-8.commonpaddingnew {
    padding: 15px 15px;
  }
  .buttonpadding {
    display: inline-block;
    width: 100%;
  }
  .left_home_banner_contentnew1 h1 br {
    display: none;
  }
  .animations_New svg {
    transform: translate3d(-205px, -23px, 0) !important;
    width: 500px !important;
  }
  .middle_content_txt.middle_content_txthome .left_home_banner_contentnew1 h1 {
    font-size: 30px;
  }
  .left_home_banner_contentnew1 p br {
    display: none;
  }
  /* .outer_bg_section {
    padding: 3px 9px;
   } */

  .personal_data_title h2 {
    font-size: 20px;
  }

  .divider {
    margin: 1px 0 0 0;
  }

  .steper_middle {
    width: 100%;
  }

  .h-full .talenter_profile_middle.full_width_middle,
  .h-full .steper_middle {
    width: 100%;
  }

  .steper_middle {
    overflow-x: auto;
  }

  .talent_left_active_middle,
  .middle_content_txt {
    width: 100%;
  }

  .profile p br {
    display: none;
  }

  .profile h3 {
    font-size: 19px;
  }

  .recu_list h3,
  .talent_left_active_users_inner h4 {
    font-size: 24px;
  }

  .recu_list p {
    font-size: 19px;
    line-height: 28px;
  }

  .profile img {
    max-width: 60px;
  }
  .MuiDateCalendar-root.fix-data {
    width: 261px;
  }
}

@media only screen and (max-width: 800px) {
  .talent__middle_section {
    width: 100%;
  }

  .profile p br {
    display: none;
  }

  .left_home_banner_content br {
    display: none;
  }
  .phn_respon{    flex-direction: column;
    gap: 10px;display: flex;}
    .phn_respon .phone_number{width:100%} .person_data{display: flex !important;flex-direction: column !important;align-items: start !important;}
    .person_data .person_data_details{width:100% !important;max-width: 100% !important;}
    .unified__main_outer .main__chat {
      /* margin-left: 20px; */
      margin-top: 10px ;
      margin-left: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .landing_page_section.recruiters {
    min-height: 443px;
    padding-bottom: 50px;
  }
  .display_left {
    margin: 0 35px 0 0;
  }
  .bm-burger-button {
    display: block;
  }
  .mobilenavemenu a {
    font-size: 16px;
    font-weight: 600;
    color: #101820;
    background: url("../img/backarrow.png") 0px 5px no-repeat;
    padding: 0 0 0 15px;
  }
  .mobilenavemenu {
    display: block;
    cursor: pointer;
  }
  .header_left.mobilemenuhidden {
    display: none;
  }
  .header_left {
    min-height: 60px;
  }
  .newmobileiconcolor {
    position: absolute;
    top: 10px;
    right: 47px;
  }
  .res_newmobileiconcolor{
    position: absolute;
    top: 10px;
    right: 5px !important;
    display: block;
  }
  .res_newmobileiconcolorOuter {
    position: absolute;
    top: 10px;
    right: 50px !important;
    display: block;
}

  .weblogo {
    padding: 8px 0 0 0;
    vertical-align: middle;
  }
  /* .recruiterhomepage .newmobileiconcolor{top: 13px;right: 15px;} */
  .outer_bg_section {
    padding: 14px 20px;
  }
  .common_footer_section .webhidden {
    display: none;
  }
  .common_footer_section .mobileopen {
    display: block;
  }
  .footer_social_media label {
    font-size: 14px;
    margin: 10px 0 0 0;
  }
  .footer_social_media {
    margin: 20px 0 0 0;
    text-align: center;
  }
  .common_footer_section {
    padding: 28px 0;
  }
  .paddingtopremove p.project_title_input {
    width: 100% !important;
    white-space: pre-wrap;
  }
  .float_rightbutton.float_riht {
    float: right;
    width: auto;
  }
  .userprofileadd_infomations p {
    margin: 3px 0 0px 0;
  }
  .pl-3.padding00 {
    padding-left: 0px;
  }
  .middle_section .MuiToolbar-regular .MuiTablePagination-actions {
    margin-left: 0px;
  }
  .middle_section .css-12ovgqq-MuiInputBase-root-MuiTablePagination-select {
    margin-right: 10px;
  }
  .shortlistcommongnew #TalentMenuCard .Talent_name {
    font-size: 17px;
  }
  .posterHeading {
    font-size: 11px;
  }
  .lengthText b {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
    line-height: 13px;
  }
  .lengthText {
    display: inline-block;
    width: 87%;
    vertical-align: middle;
    line-height: 18px;
    margin: 8px 0 0 0;
  }
  .LogomobileView {
    vertical-align: middle;
    display: inline-block;
    margin: 6px 0 0 0;
  }
  .flex .flexdisplayblock {
    display: inline-block;
    width: 100%;
  }
  .ButtonLeftt#custom_button {
    float: left;
  }
  .ButtonRightt#custom_button {
    float: right;
  }
  .NormalText {
    display: inline-block;
    vertical-align: middle;
    margin: 7px 0 0 0;
  }
  .Load_search_list .diaalogpadding {
    padding: 0 1rem;
  }
  .Load_search_list #LoadAndSavePopup .title {
    font-size: 17px;
  }
  .middle_bar_section .MuiInputBase-colorPrimary {
    margin-right: 10px;
  }
  .middle_bar_section .MuiTablePagination-actions {
    margin-left: 0px;
  }
  .middle_section .show_onlyActive {
    display: inline-block;
    text-align: left;
  }
  .shortlistcommongnew #TalentListCard {
    margin-left: 0px;
  }
  .roundedbxcarditsbal .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
  .mobiledisplaynoneblock {
    display: inline-block;
    width: 100%;
  }
  .casting_how_it_works {
    padding: 36px 0;
  }

  .sm_displayNone {
    display: none;
  }
  .top_tobottom {
    display: none;
  }
  .talent_uniquerolesecmidtheme1 .talent_left_uniquermiddle:before,
  .middle_content_txthome:before {
    display: none;
  }
  .landing_page_section {
    background: none;
  }
  .recu_list {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .landing_page_section .left_home_banner_content h1 {
    line-height: 32px;
  }
  .casting_recruiters_list.reducetoppadding#your_talent {
    padding-bottom: 16px;
  }
  .left_home_banner_contentnew1.hiddenwebpage {
    display: none;
  }
  .left_home_banner_contentnew1.showinmobile {
    display: block;
    margin-top: 116px;
    min-height: 300px;
  }
  .home_landing {
    background: #fff !important;
  }
  .left_home_banner_contentnew1 h1,
  .left_home_banner_contentnew1 p {
    text-align: center;
  }
  .home_landing {
    margin-top: 0;
  }
  .menu_items {
    font-size: 14px;
  }
  .dropdownArea.commonfindjobsection {
    width: 100%;
  }
  .PopupSection {
    overflow-x: auto;
  }
  .hiddenmobile {
    display: none;
  }
  .openinmobile {
    background: url("../img/Icon.JobSearch.svg") center center no-repeat;
    cursor: pointer;
    width: 27px;
    height: 27px;
    background: url("../img/Round.svg") center center no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-size: 100%;
  }
  #LoadAndSavePopup .instruction br {
    display: none;
  }
  .openinmobile.IconChange {
    background: url("../img/Icon.JobSearch.svg") center center no-repeat;
    background-size: 100%;
  }
  #ShareProjectPopup.UserprofileImage h2.shareProject_title {
    font-size: 18px;
    margin: 0px;
  }
  .person_data {
    display: flex !important;
    flex-direction: row !important;
    /* align-items: start !important; */
    gap: 10px;
    margin-top: 10px;
}

}

@media only screen and (max-width: 568px) {
  .top_profile_section button.MuiButtonBase-root {
    padding: 0px;
  }
  .profile:after {
    display: none;
  }
  .left_home_banner_contentnew1.showinmobile {
    display: block;
    margin-top: 41px;
    min-height: 150px;
    height: auto;
    padding-bottom: 30px;
  }
  .findtalents .PopupSection {
    width: 100%;
    display: inline-block;
  }
  .animations_New svg {
    width: 100% !important;
    transform: translate3d(-68px, 11px, 0px) !important;
  }

  .castingbell_logo .logoImage img {
    max-width: 133px;
  }
  .photo_action_contianer {
    min-width: 130px !important;
  }
  .validations {
    width: 23px;
    background-size: 100%;
    height: 23px;
  }
  .photo_id_flex{padding-left: 0 !important;margin-top: 10px !important;}
  .MuiStepper-root .MuiStep-root:nth-child(2) .MuiStepConnector-root.Mui-active .MuiStepConnector-line:before {display: none;}
  .MuiStepper-root .MuiStep-root:nth-child(3) .MuiStepConnector-root.Mui-active .MuiStepConnector-line:before {
    content: "33% done";
    display: none !important;
}
.MuiStepper-root .MuiStep-root:nth-child(4) .MuiStepConnector-root.Mui-active .MuiStepConnector-line:before {display: none;}
.MuiStepper-root .MuiStep-root:nth-child(5) .MuiStepConnector-root.Mui-active .MuiStepConnector-line:before {display: none;}
.MuiStepper-root .MuiStep-root:nth-child(6) .MuiStepConnector-root.Mui-active .MuiStepConnector-line:before {display: none;}
.MuiDialog-paper.MuiDialog-paperScrollPaper {
  max-width: 600px !important;
  width: 500px !important;
}
.image_loaderOuter .MuiDialog-paper.MuiDialog-paperScrollPaper {width:auto !important}
.justify-end_outer{justify-content: center;}
.images_uploders_outer .img_flex{
  margin-left:0 !important;margin-right: 17px  !important;
}.my-masonry-grid_outer {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  justify-content: center;

}.image_items_size_outer {
  min-height: 150px;
  min-width: 100px;
}
.my-masonry-grid_column_outer {
  background-clip: padding-box;
  margin: 0px 4px 4px 4px;
  width: calc(150px - 8px) !important;
}
.back_justify{display: flex;flex-direction: column;    gap: 12px;}
.back_justify #custom_backbutton.MuiButton-root {width:66%}
.back_justify_button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 66%;
}
.infoText_outer{text-align: center;padding: 20px;} .left_cover_images_inner img{object-fit: cover;}
.cometchat_outer {
  border: none;
  padding-left: 21px;
  padding-right: 12px;
}
.chat__sidebar-menu{background-color: #9B26B6 !important;}
.unified__main_outer .main__chat {
  /* margin-left: 20px; */
  margin-top: 0 ;
  margin-left: 0 !important;
}
.MuiPaper-root.MuiPaper-elevation.chat_responce{margin-bottom: 0;}

}

@media screen and (min-width: 1465px) {
  .images_container {
    width: 89%;
  }
}

@media screen and (min-width: 1600px) {
  .images_container {
    width: 80%;
  }
}

@media screen and (min-width: 1800px) {
  .images_container {
    width: 75%;
  }
}

@media screen and (min-width: 2000px) {
  .images_container {
    width: 67%;
  }

  .circle {
    left: 85%;
    bottom: 50px;
  }
}

@media screen and (min-width: 2100px) {
  .images_container {
    width: 65%;
  }
}

@media screen and (min-width: 2150px) {
  .images_container {
    width: 63%;
  }
}

@media screen and (min-width: 2230px) {
  .images_container {
    width: 61%;
  }
}

@media screen and (min-width: 2280px) {
  .images_container {
    width: 60%;
  }
}

@media screen and (min-width: 2300px) {
  .images_container {
    width: 59%;
  }
}

@media screen and (min-width: 2350px) {
  .images_container {
    width: 57%;
  }
}

@media screen and (min-width: 2400px) {
  .images_container {
    width: 55%;
  }
}

@media screen and (min-width: 2550px) {
  .images_container {
    width: 53%;
  }
}

@media screen and (min-width: 2700px) {
  .images_container {
    width: 50%;
  }
}

@media screen and (min-width: 2830px) {
  .images_container {
    width: 48%;
  }
}

@media screen and (min-width: 2900px) {
  .images_container {
    width: 45%;
  }
}

/* @media screen and (min-width: 4000px) {
   .images_container {
    width: 33%;
   }
  } */
/* 1040  1366 1280 1024 768 568 414 320  */

/* Admin profile page */

@media screen and (max-width: 568px) {
  .middle_content_txt.middle_content_txthome .left_home_banner_contentnew1 h1 {
    font-size: 20px;
  }
  .float_rightbutton.float_riht {
    width: 100%;
  }
  .singup__screen.signup_open .auth_btn {
    width: 100%;
    max-width: 100%;
  }
  .PopupSection.widthincress {
    width: 260px;
  }
  .topfindmyjobsection .gen {
    width: 100%;
  }
  .css-1ytbaz1 {
    width: 100%;
  }
  .start_butt button,
  .recu_list button,
  .recu_list button {
    height: 39px;
    padding: 0 17px;
    font-size: 15px;
  }
  .header_top_section.beforetalenterhomepage .lengthText {
    width: 194px;
  }
  .userprofileadd_infomations {
    margin-top: 13px;
    padding-left: 10px;
  }

  .Homepagereamovepadding .display_left {
    margin: 4px 10px 0 0;
  }
  .notification_container .textFeild {
    width: 100%;
  }
  .additionalclass .p-4 {
    padding: 11px 0;
  }
  .border_bottom.RightButton {
    float: left;
  }
  .personal_data_section .switch_design_right {
    margin: 27px 0 0 29px;
  }
  .lefttext {
    display: inline-block;
    width: 65%;
  }
  .sm_displayBlock {
    display: block;
  }
  .layout_min_height .audit_preview_common {
    padding: 15px 15px;
  }
  .audit_preview_common .user_preview_info .projectName {
    font-size: 18px;
  }
  .audit_preview_common .user_preview_info label {
    font-size: 15px;
  }
  .top_profile_section {
    padding: 5px 0 0 0;
  }
  .container.px-6.padding15 {
    padding: 0 15px;
  }
  .profile_compliction_scor {
    padding: 0px 0 0px 0;
  }
  .PopupSection .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  #composition-button.MuiButton-root {
    font-size: 14px;
  }
  .textdipslay.break-normal {
    display: inline-block;
  }
  .recruiter_text {
    font-size: 13px;
  }
  .audit_preview_common.padding00 .mod_left__sec h2 {
    font-size: 15px;
  }
  .flex.display_block {
    display: inline-block;
  }
  .flex.display_block #custom_backbutton.MuiButton-root {
    width: 100%;
    margin-bottom: 10px;
  }
  .flex.display_block #custom_button.MuiButton-root {
    width: 100%;
  }
  .MuiDialog-scrollPaper p#firrt-dialog-description {
    font-size: 14px;
  }
  .newreducepadding .middle_bar_section {
    padding-left: 0;
  }
  .pr-7.mobilepadding {
    padding-right: 0px;
  }
  .mobilepadding .talentprojectTitle {
    font-size: 13px;
  }
  .mobilepadding .talentproject_name,
  .mobilepadding .talentproject_text {
    font-size: 14px;
  }
  .mobilepadding #custom_button.MuiButton-root {
    font-size: 14px;
  }
  .flex .drop_menuadjus {
    display: inline-block;
    width: 100%;
  }
  .p-6.newSub1 {
    padding: 15px;
  }
  .newSub1 .project_name {
    font-size: 18px;
  }
  .topflex .flex.filterOption {
    display: inline-block;
    width: 100%;
  }
  .topflex .flex.filterOption p {
    text-align: left;
    margin-top: 7px;
  }
  .right_but_info.paddingleft15 {
    padding-right: 15px;
  }
  .paddingadjsnew.top_search_section {
    padding: 0 16px 15px;
  }
  .outer_bg_section_data .MuiButtonBase-root {
    font-size: 12px;
  }
  #PastWorks.p-10 {
    padding: 17px 23px;
  }
  .profile_name__accd.pb-6 {
    padding-bottom: 15px;
  }
  .layout_min_height #custom_button.MuiButton-root {
    font-size: 13px;
    width: 100%;
  }
  .outer_sec_button .MuiButton-root.MuiButton-contained {width:auto !important}
  .paddingadjsnew .btn_container_white .MuiButton-root {
    font-size: 12px;
    padding: 2px 8px;
  }
  .personal_data_title h2 {
    font-size: 16px;
  }
  .formLabelText .MuiTypography-root {
    font-size: 12px;
  }
  .personal_data_title {
    padding: 13px 0 0px 0;
  }
  .addMore_btn {
    padding: 2px 0 0 7px;
  }
  .top_profile_section #custom_button.MuiButton-root {
    padding: 2px 13px;
    font-size: 12px;
    min-height: 28px;
  }
  /* section */
  .top_profile_section {
    padding-bottom: 0;
  }
  #TalentCard.removepadding15 .talent_name {
    font-size: 13px;
  }
  #TalentCard.p-6.removepadding15 {
    padding: 15px;
  }
  .genderincon img {
    max-width: 20px;
  }
  .px-6.commonpadding15 {
    padding: 15px 15px;
  }
  .commonpadding15 .css-s2fxmk-MuiList-root {
    width: 100%;
  }
  .ErrorPageHeight .notFoundText {
    font-size: 20px;
  }
  .ErrorPageHeight .oopsText {
    font-size: 30px;
  }
  .errorContent {
    font-size: 14px;
  }
  .px-6.paddingchange {
    padding-left: 15px;
    padding-right: 15px;
  }
  .findtalents .p-7 {
    padding: 15px;
  }
  .findtalents .searchHeading {
    font-size: 18px;
    display: inline-block;
    width: 100%;
    margin: 0 0 14px 0;
  }
  #custom_button.MuiButton-root {
    font-size: 12px;
    padding: 2px 12px;
  }
  .MuiPaper-elevation .paddingreduce {
    display: inline-block;
    width: 100%;
  }
  .paddingreduce #custom_backbutton.MuiButton-root {
    display: inline-block;
    width: 100% !important;
  }
  .paddingreduce #custom_button.MuiButton-root {
    display: inline-block;
    width: 100% !important;
    margin: 7px 0;
    line-height: 14px;
  }
  .MuiDialog-scrollPaper .commonpaddingnew {
    padding: 0px 0rem;
  }
  #RecruiterUnlock .title,
  #RecruiterUnlockType #RecruiterUnlock .title {
    font-size: 18px !important;
  }
  .flex.widthincress {
    display: inline-block;
    width: 100%;
  }
  .widthincress #Character_shortlist_dropdown .border_bottom [type="text"] {
    font-size: 14px;
  }

  .flex.newbuttonsalign {
    display: inline-block;
    width: 100%;
  }
  .newbuttonsalign #composition-button {
    width: 100%;
    margin: 4px 0 !important;
  }
  .commonfindjobsection.padding_full {
    padding: 0 15px;
  }
  .PopupSection svg.MuiSvgIcon-fontSizeMedium {
    font-size: 33px;
  }
  .PopupSection .span img {
    width: 30px;
  }
  .MuiSvgIcon-fontSizeInherit.mobilehidden {
    display: none;
  }
  .rsmobileopen {
    display: block;
    font-size: 13px;
    font-weight: 600;
    border: 1px solid #9b26b6;
    padding: 0px 48px;
    height: 38px;
    vertical-align: top;
    border-radius: 5px;
    color: #9b26b6;
    line-height: 38px;
    width:100%
  }
  button.buttonLeft {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }
  .ButtonLeftt_talent{margin-bottom: 10px !important;}
  .width100pers {
    width: 100%;
  }
  #custom_button.buttonRight {
    height: 31px;
  }
  .controlthepadding #custom_button.MuiButton-root {
    font-size: 13px;
  }
  .controlthepadding #projectCreatedPopup .PopupContent {
    font-size: 15px;
  }
  .controlthepadding #projectCreatedPopup .title {
    font-size: 20px;
  }
  .character_title {
    font-size: 16px;
  }
  .paddingreduce .character_title {
    font-size: 16px;
  }
  .paddingreduce #custom_button.MuiButton-root {
    font-size: 13px;
  }
  .middle_bar_section #TalentCard .talent_name {
    font-size: 14px;
  }
  .MuiDialogContent-root.addpadding {
    padding: 0 1rem;
  }
  #TalentCard .sharedProjectCard h3 {
    font-size: 17px;
  }
  .PopupSection.Hair img {
    max-width: 30px;
  }
  .PopupSection.width100p {
    display: inline-block;
    width: 100%;
  }
  .marginbt10 {
    margin-bottom: 20px;
  }
  .FindD_Talents ul {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 7px;
  }
  .findtalents .talent_slider,
  .findtalents p.talent_showAll.mr-3 {
    display: none;
  }
  .commonfindjobsection .DropdownHeading {
    padding: 9px 0 5px 16px;
    font-size: 15px;
  }
  .widthchangenew {
    min-width: 97px;
  }
  .talent_img_view button svg {
    width: 24px;
  }
  .value_broder #Character_shortlist_dropdown .border_bottom [type="text"] {
    font-size: 14px;
  }
  .flex.changemobilewidth {
    display: inline-block;
    width: 100%;
    padding-right: 15px !important;
  }
  .flex.changemobilewidth #custom_button {
    margin: 5px 0;
  }
  .flex.changemobilewidth .Widthleft {
    width: 100%;
  }
  .faverlistnew {
    display: none !important;
  }
  #Character_shortlist_dropdown {
    display: inline-block;
    width: 100%;
  }
  #Character_shortlist_dropdown .border_bottom [type="text"] {
    font-size: 13px !important;
  }
  span.phot_close__icon {
    width: 25px !important;
    height: 25px !important;
  }
  span.phot_close__icon svg {
    font-size: 16px !important;
    vertical-align: middle;
  }
  .mobiledisplaynone {
    display: inline-block;
    width: 100%;
  }
  .mobiledisplaynone #custom_button.MuiButton-root.disableBtn {
    width: 100%;
    margin: 4px 0;
  }
  .mobiledisplaynone .remove_link {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .gridviewnewsection {
    display: inline-block;
    width: 100%;
  }
  .gridviewnewsection div {
    display: inline-block;
    width: 50%;
    padding: 0 3px;
  }
  .gridviewnewsection div .MuiTypography-root .ListCard {
    width: 100% !important;
  }
  .mobiledisplaynone #custom_button.MuiButton-root.white {
    width: 100%;
    margin: 2px 0 4px 0;
  }
  .shortlistcommongnew .image_container.cursor-pointer.gridviewnewOPT {
    width: 47%;
    display: inline-block;
  }

  .left_user_profile {
    display: inline-block;
    width: 100%;
    margin-bottom: 17px;
  }
  .p-10.paddingreduce {
    padding: 15px;
  }
  .project_title_input {
    font-size: 16px !important;
  }
  .paddingreduce .character_sub_title {
    font-size: 13px;
    line-height: 18px;
  }
  .submit_buttons_popup.Posting_details .flex.flex-wrap.gap-3.fullwidthsec {
    display: inline-block;
    width: 100%;
  }
  .submit_buttons_popup.Posting_details #custom_button.MuiButton-root {
    width: 100%;
    margin: 4px 0;
  }
  .widthchanges {
    max-width: 100% !important;
  }
  .job_post_uplo.fullwidth #custom_button {
    width: 100%;
  }
  .widthchanges {
    padding-right: 15px !important;
  }
  .layout_min_height .top_profile_section {
    padding: 7px 0;
  }
  .dropDwanchange {
    width: 100% !important;
  }
  .showbuttons #custom_button {
    width: 100%;
  }
  .showbuttons {
    display: inline-block;
    width: 100%;
    margin: 12px 0 0 0;
  }
  .padding_leftright #custom_button.MuiButton-root {
    margin: 3px 0;
  }
  .close_project .project_addDoc {
    font-size: 14px;
  }
  .paddingtopremove .close_project {
    margin: 4px 15px;
  }
  .padding_leftright {
    padding: 0px !important;
  }
  .commonpaddingnewnewT {
    padding: 0 0px !important;
  }
  .middle_bar_section .sharedProjectCard h3 {
    font-size: 15px;
  }
  .top_profile_left #custom_button.MuiButton-root {
    font-size: 11px;
    padding: 2px 8px;
    min-height: 32px;
  }
  .flex.displayblock {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .authContainer {
    width: 100%;
  }
  .MuiDialog-container .first_alert__title {
    font-size: 18px !important;
  }
  .MuiDialog-scrollPaper p#firrt-dialog-description br {
    display: none;
  }
  .NewPopupClassClose #custom_button.MuiButton-root,
  .NewPopupClassClose #custom_backbutton.MuiButton-root {
    font-size: 12px;
    padding: 2px 13px;
  }
  .act__user_profile .slick-next:before,
  .act__user_profile .slick-prev:before {
    font-size: 21px;
  }
  .talent_uniquerolesecmid.talent_uniquerolesecmidtheme1 {
    padding: 40px 0 45px 0;
  }
  .findtalents .character_title {
    font-size: 18px;
  }
  .middle_bar_section.padding_left00 {
    padding-left: 0px;
  }
  #PhotoGrallery .slick-next {
    right: 1px;
    top: 47%;
  }
  .px-6.paddingreduceNew {
    padding: 0px;
    padding-right: 15px;
  }
  #PhotoGrallery .slick-prev {
    left: 0;
    top: 47%;
    z-index: 2;
  }
  .audit_preview_common {
    padding: 16px 16px;
  }
  .layout_min_height .heading {
    font-size: 18px;
  }
  .NormalLogo {
    vertical-align: middle;
    display: inline-block;
    margin: 9px 0 0;
  }
  #custom_button.MuiButton-root.deleteBtn,
  #characterDelete #custom_backbutton.MuiButton-root {
    padding: 2px 6px;
    font-size: 14px;
  }
  #PhotoGrallery .act__user_profile {
    margin-top: 0px !important;
  }
  .flexdisplayblock .width100pers.marginbottomTop {
    margin-top: 10px !important;
  }
  .paddingtopremove [name="project_title_input"] {
    font-size: 15px;
  }
  .roundedbxcarditsbal .MuiTablePagination-actions {
    margin-left: 0 !important;
  }
  .flex.justify-end.gap-5.justifyMobileLeft {
    justify-content: inherit;
  }
  .layout_min_height .audit_preview_common.padding00 {
    padding: 15px 0 0 0px;
  }
  .shortlistcommongnew #TalentListCard {
    width: 100%;
  }

  .shortlistcommongnew #TalentListCard .ListCard img {
    width: 100%;
  }

  .shortlistcommongnew
    #TalentListCard
    span.MuiTypography-root.MuiTypography-body1 {
    width: 100%;
  }
  .flex-wrap.with100 {
    display: inline-block;
    width: 100%;
  }
  .flex-wrap.with100 .float_rightButton {
    float: right;
  }
  .flex-wrap.with100 .faverlistnew_mobile {
    display: inline-block;
    margin: -7px 0 0 0;
  }
  .customScrollBar.imageArea_container.mt-4 {
    display: inline-block;
    width: 100%;
  }
  .paddingchange .image_container {
    display: inline-block;
    width: 50%;
    height: 194px;
  }
  .paddingchange .image_container > img {
    padding: 0 0 0 3px;
  }
  .openAuditionButton br {
    display: none;
  }
  .openAuditionButton p {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }
  .shortlistcommongnew .ListCard p {
    font-size: 13px;
  }
  .flexremove.paddingremove {
    padding: 18px 15px;
  }
  .custom_dropdown_icons.eye_color .dropdown__icons__list,
  .form_left.left_move_mt .dropdown__icons__list,
  .custom_dropdown_icons.hair_color .dropdown__icons__list {
    width: 100%;
  }
  .paddingcommonLeftright {
    padding: 11px 15px;
  }
  /* .Homepagereamovepadding .newmobileiconcolor {right: 39px;top: 10px;}*/
  .beforetalenterhomepage.mainLanding .newmobileiconcolor {
    right: 39px;
    top: 10px;
  }
  .beforetalenterhomepage.publicLanding .newmobileiconcolor {
    right: 50px;
    top: 14px;
  }
  .Moblie_edit_img svg {
    color: #9b26b6;
  }
  .personal_data_section #custom_backbutton.MuiButton-root {
    font-size: 13px;
  }
  .talent_uniquerolesecmid {
    padding: 32px 0 11px 0;
  }
  .screenRange {
    padding: 0 8px;
  }
  .job_list_common .common_title {
    font-size: 20px;
  }

  .rightbuttonsleft .postitionright#custom_button {
    position: absolute;
    right: 7px;
    top: 0px;
    z-index: 2;
    width: auto;
  }
  .px-6.paddingequal {
    padding-left: 15px;
  }

  .shortList_hover .textEllipsis {
    font-size: 13px;
  }
  .shortlistcommongnew .ListCard p .shortText {
    -o-text-overflow: ellipsis; /* Opera */
    text-overflow: ellipsis; /* IE, Safari (WebKit) */
    position: relative;
    top: 5px;
    overflow: hidden; /* don't show excess chars */
    white-space: nowrap;
    display: inline-block; /*force single line */
    width: 113px;
  }
  .inner_video {
    width: 100%;
  }
  .NameTagChange {
    display: inline-block;
    width: 100%;
  }
  .shortTextIcons {
    display: inline-block;
    float: right;
    vertical-align: top;
    margin: 0px;
    padding: 0px;
  }
  #PastWorks .outer_bg_section_data .slick-arrow.slick-next {
    right: 2px;
  }
  .home_landingbottomscrollsection {
    display: none;
  }
  .newSub1 .project_catg > .count_value {
    font-size: 14px;
  }
  .display_blockse {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  .gap-3.mobilepaddingremove {
    padding: 15px 1rem 25px;
  }
  .gap-3.mobilepaddingremove #custom_button.MuiButton-root {
    padding: 2px 6px;
    font-size: 12px;
  }
  .gap-3.mobilepaddingremove #custom_backbutton.MuiButton-root {
    font-size: 12px;
  }
  .gap-3.mobilepaddingremove .title,
  .innerpaddingreduc #ShareProjectPopup .shareProject_title {
    font-size: 18px;
  }
  .innerpaddingreduc .addpadding ul {
    display: inline-block;
    width: 100%;
  }
  .innerpaddingreduc .addpadding .mt-5.two-column {
    -webkit-columns: 1;
  }
  #ShareProjectPopup.innerpaddingreduc .shareProject_title.mt-7 {
    font-size: 17px;
  }
  #ShareProjectPopup p.shareProject_subtitle.mt-3.mb-5 {
    font-size: 16px;
  }
  .gap-8.fullwidth {
    display: inline-block;
    width: 100%;
  }
  #LoadAndSavePopup .title.mt-4 {
    font-size: 18px;
  }
  .innerpaddingreduc #custom_button.MuiButton-root {
    font-size: 13px;
  }
  .Jobpostcommon .MuiTablePagination-actions {
    margin-left: 0px;
  }
  .Jobpostcommon .MuiInputBase-colorPrimary {
    margin-left: 0px;
  }
  .fourbnottext {
    font-size: 15px;
    color: #44454d;
    text-align: center;
    line-height: 19px;
    font-weight: 600;
  }
  #TalentCard .talent_name {
    display: inline-block;
    width: 100%;
  }
  #TalentCard .talent_name .unlocked_date {
    float: right;
  }

  #RecruiterUnlock .content,
  #RecruiterUnlockType .content {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .cms-para {
    padding: 17px 15px;
  }
  .cms_section_new {
    margin: 20px 0 0 0;
  }

  .middle_bar_section {
    padding: 13px 0;
    padding-left: 15px;
  }

  /* header --> profile name */
  .profileDropDownButton {
    background: none;
  }
  .top_title h1 {
    font-size: 18px;
  }

  .top_title h2 {
    font-size: 16px;
  }

  /* user name */
  .profile_name_left h3 {
    font-size: 16px;
  }

  .unfave_icon_fave_icons {
    margin-top: 12px;
  }

  .menu_items {
    line-height: 30px;
  }

  /* photo gallery  */
  .active_menu_bar {
    bottom: -12px;
  }

  .act__user_profile_top h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .act__user_profile_top h3 span {
    display: flex;
  }

  .profile_name__accd h3 {
    font-size: 15px;
  }

  .profile_name__accright.flex {
    float: none;
    margin: 0 0 19px 0;
  }

  /* .profile_name__accright .MuiButton-root {
    right: 42px;
    top: -58px;
    line-height: 21px;
    position: absolute;
   } */

  .Moblie_edit {
    right: 57px;
    top: -57px;
    line-height: 21px;
    position: absolute;
    cursor: pointer;
  }

  .images_uploader_left {
    padding-left: 16px !important;
    padding-top: 22px;
    padding-right: 17px !important;
  }

  .footer_left_item .heading {
    padding: 7px 0 0px 0;
  }

  .circle {
    left: 87%;
  }

  .footer_social_media ul li {
    padding: 6px 11px 0 0px;
  }

  .footer_left_item ul li {
    padding: 0;
  }

  .footer_left_item p {
    margin: 10px 0 0 0;
  }

  .footer_social_media p {
    padding-top: 10px;
    color: #000;
    font-weight: 500;
  }

  /* job cards --------------> */
  .slect_list {
    margin-left: 5px;
  }

  .right_but_info {
    padding-left: 16px;
  }

  .ErrorPageHeight {
    height: fit-content;
  }
  /* subcription page */
  .table_text {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .vertical-Text {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  .table_plan_error {
    right: 14px !important;
    top: 28% !important;
    font-size: 16px !important;
    line-height: 21px !important;
  }
  .table_price {
    font-size: 9px !important;
    line-height: 16px !important;
  }
  /* landing page */

  .left_home_banner_content strong {
    text-align: center;
  }
  .left_home_banner_content h1 {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
  .left_home_banner_content p {
    font-size: 14px;
    text-align: center;
  }
  .landing_page_section .find_button button {
    height: 47px;
    padding: 0px 20px;
    font-size: 16px;
  }
  .profile {
    margin: 20px 0 0 0;
  }
  .profile img {
    max-width: 100px;
  }
  .recu_list h3,
  .recu_list.style_right h3 {
    text-align: center;
  }
  .recu_list p,
  .recu_list.style_right p {
    text-align: center;
  }
  .casting_recruiters_list {
    padding: 0;
  }
  .left_home_banner_img {
    padding: 1% 0 0;
  }
  .left_home_banner_img img {
    width: 85%;
  }

  .outer_bg_section.recruiterFrom {
    padding: 14px 28px 14px 14px;
  }
  .recruiterFrom #custom_button.MuiButton-root {
    padding: 2px 7px;
  }
  .stepper__icon__contianer.createProject {
    width: 100%;
  }
  .outer_bg_section.auditionFrom {
    padding: 14px 19px;
  }
  .character_librafry .MuiGrid-root.MuiGrid-item.grid_row {
    width: 100% !important;
  }
  .character_librafry .MuiButtonBase-root.MuiButton-root.add-vx {
    width: 100% !important;
  }
  .content_box_outer{width:100% !important}
  .ImageArea{margin-top: 10px;}
  .outer_openigs{margin-top: 20px;}

}
@media screen and (max-width: 520px) {
  .audit_preview_common {
    padding: 14px 14px;
  }
  .table_plan_error {
    top: 26% !important;
  }
  .header_top_section .singup__screen {
    padding: 41px 19px 0 19px;
  }
  .singup__screen.signup_open .role_selecter1 .MuiFormGroup-root {
    width: 100%;
  }
  .form_action_container .auth_btn {
    width: 100%;
    max-width: 100%;
  }
  .character_librafry {
    margin-left: 0 !important;
  }
  .btn_rightbx {
    flex-direction: column;
    gap: 10px !important;
  }
  .character_librafry label {
    font-size: 10px !important;
  }
  .Save_vx {
    margin-right: 0 !important;
  }
  .outer_formatik .outer_formatik_tooltip {
    width: 100% !important;
  }
  .outer_formatik .tool_flex {
    width: 100% !important;
  }
  .disable_pattern {
    width: 100% !important;
  }
}
@media screen and (max-width: 460px) {
  .table_plan_error {
    top: 26% !important;
  }
  .value.newwidth,
  .value.newwidth {
    width: 225px;
  }
  .cursor-pointer.spacebottomtop {
    padding: 10px 0 0 0;
  }
  .reducethepopupBox {
    width: 276px !important;
  }
  .shortlistcommongnew .ListCard p .shortText {
    width: 85px;
  }
  .middle_section .shortlist_showAll {
    margin-top: 14px;
  }
  .character_librafry {
    margin-left: 0 !important;
  }
  .character_librafry label {
    font-size: 13px !important;
  }
  .btn_rightbx {
    flex-direction: column;
    gap: 10px !important;
  }
  .btn_rightbx {
    padding-right: 0 !important;
  }
  .text_creat_form {
    padding-bottom: 25px !important;
  }
  .rsmobileopen_outer {
    width: calc(100% + 16px);
    /* margin-left: -8px; */
  }
  /* .button_outer_flex{display: flex;flex-direction: column;
  gap:10px;padding:0 10px} */
  .disable_pattern {
    width: 100% !important;
  }

  #custom_button.MuiButton-root.disableBtn.ButtonRightt {
    margin-right: 0 !important;
    margin-top: 10px !important;
  }
  .justify_content {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
  }
  .justify_content .justify_content_tags {
    text-align: center !important;
  }
  .btn__container_outerr{

      flex-direction: column; 
   justify-content: center;
    align-items: center;
  gap: 15px; 
}
}

@media screen and (max-width: 367px) {
  .shortlistcommongnew .image_container.cursor-pointer.gridviewnewOPT {
    width: 47%;
    display: inline-block;
  }
  .table_plan_error {
    top: 24% !important;
  }
  .custom_dropdown_icons.eye_color .dropdown__icons__list,
  .dropdown__icons__list,
  .custom_dropdown_icons.hair_color .dropdown__icons__list {
    width: 100%;
  }
  .icon__box {
    width: 27.6%;
  }
  .value.newwidth {
    width: 200px;
  }
  /* .rsmobileopen{padding: 0px 23px;font-size: 13px;
      line-height: 37px;} */
  .rsmobileopen_outer {
    width: 328px;
    margin-left: -8px;
  }
  .paddingchange #custom_button.MuiButton-root {
    padding: 2px 12px;
  }
  .shortlistcommongnew .ListCard p .shortText {
    width: 85px;
  }
  .middle_section .shortlist_showAll {
    margin-top: 14px;
  }
  .character_librafry {
    margin-left: 0 !important;
  }

  .character_librafry label {
    font-size: 13px !important;
  }
  .btn_rightbx {
    flex-direction: column;
    gap: 10px !important;
  }
}
@media screen and (max-width: 1000px) {
  .footer_leftpadding {
    padding: 0;
  }
}

@media screen and (max-width: 420px) {
  .auth_btn {
    padding: 11px 11px 11px 35px !important;
  }

  .home_landing {
    padding-top: 20px;
  }
  .logoImage {
    width: 80%;
  }
  .recaptcha {
    transform: scale(0.75);
    transform-origin: 0 0;
  }
  .rsmobileopen {
    padding: 0px 38px;
  }
}

@media screen and (max-width: 367px) {
  .auth_btn {
    padding: 11px 11px 11px 25px !important;
  }
  .filterArea.flex-wrap .gap-10 {
    gap: 0.5rem;
  }
}

/* notification drop down ----------------------------------------------> */
@media screen and (max-width: 740px) {
  #account-menu li.MuiMenuItem-root {
    max-width: 470px !important;
    min-height: 25px !important;
  }
}

@media screen and (max-width: 668px) {
  #account-menu li.MuiMenuItem-root {
    max-width: 400px !important;
    min-height: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .image_items_size_printShoot {
    min-height: 167px !important;
    min-width: 70px !important;
  }
  .ImageArea {
    min-height: 100% !important;
  }
  .gap_chips {
    gap: 10px;
  }
  .rightbuttonsleft .prfl_right{margin-left: 0 !important;}
  .outer_right{margin-bottom: 10px !important;}
.flex_out{margin-top: 20px !important;}
.web_uses{display: none;}
.res_use{display: block;}
.user_imgs{margin-top:20px !important}
.message_para_outer{padding:0 !important;font-size: 11px !important;}
/* .res_useOuter{display: inline-block !important;} */
.checking_part .dissable_Save{width:200px !important}
.modal_calender_outerBox .checking_part .checking_avail {margin-right: 0;}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-labelContainer {
  padding:0;
  /* margin: 0!important; */
}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-root{padding-left: 10px;}
.double_grid_outer_box_talent .MuiPickersCalendarHeader-label {
  color: #44454D;
  font-weight: 600;
  font-size: 13px;
}
.profileDropDownButton_index{display: none;}
.profileDropDownButton_outer{
  display: block;
    position: absolute;
    /* float: right; */
    /* right: -55px; */
    top: 0;

}
/* .character_librafry_outer label{padding-right:15px} */
.character_librafry label {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.character_librafry .char_partLoc{padding-left:0 !important}.character_librafry .char_partLoc .character_librafry_outer{text-align: center;} .character_librafry .char_partLoc .character_librafry_outer span{
    word-wrap: break-word;
    text-wrap: wrap;
}
.shortlist_page{display: flex;justify-content: space-between;}
.resshortlist_page{display: block !important;}
.webshortlist_page{display: none !important;}
.talenter_profile_middle span.MuiStepConnector-line {width:60px}
.action__btn {width:300px !important}
.steper_middle_outer .MuiStepLabel-iconContainer.Mui-completed.MuiStepLabel-alternativeLabel>div{width:35px;height:35px}
.steper_middle_outer .MuiStepLabel-alternativeLabel>div {
  width:35px;height:35px
}
.steper_middle_outer .MuiStepConnector-alternativeLabel {
  top: 16px !important;
}
.stepper__icon__contianer span.MuiStepLabel-label {font-size: 6px !important;}
.chatbox_section .chat_container {margin-left:-1px !important;padding-right: 0 !important;}
.px-8.chat_container{padding: 0;}
.chat_searching input {
  position: absolute;
  z-index: 999;
  right: 3px;
  top: 16px;
  border: 1px solid #8080802e;
  border-radius: 41px;
  /* left: 209px; */
}
.id_profImg{padding-left:15px !important;padding-top: 10px;}
/* .unified__main_outer{position: absolute;bottom:100%} */
/* .right_sideChat{position: relative;} */
.chat_searching input {width: 136px !important;
  height: 38px;}
.unified__main_outer .main__chat {margin-left: 0 !important;}
.chat_process_option .MuiGrid-grid-sm-12.MuiGrid-grid-md-4 {min-height: 0 !important;}
.Empty_backRespoce{margin-top: 10px !important;}
.main__chat{z-index: 2;}
.innerdrop_menuadjus .Outerdrop_menuadjus{display: flex !important;
  flex-direction: column !important;
  height: 100px !important;       margin-top: 20px; padding-left: 14px  !important;}
  .active_menu_barInner{width:80px !important}
}
@media screen and (max-width: 530px) {
  .notificatMenulist#account-menu li.MuiMenuItem-root {
    max-width: 100% !important;
    min-height: 100% !important;
  }
  .notificatMenulist#account-menu
    .MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded:before {
    right: 104px;
  }
  .notificatMenulist#account-menu ul {
    max-height: 300px;
    overflow-y: scroll;
  }

}
@media only screen and (max-width: 450px) and (min-width: 429px) {
  .innerdrop_menuadjus .Outerdrop_menuadjus {
    display: flex!important;
    flex-direction: column!important;
    height: 100px!important;
    margin-top: 20px;
    padding-left: 14px!important;
    width: calc(100% + 24px);
    /* padding-right: 36px; */
}
.customscrollProjectOuter{max-width: 100% !important;}


}
@media screen and (max-width: 420px) {
  .header_top_section .bm-burger-button {
    top: 18px;
  }
  .relative.user_signuo_button button {
    min-height: 33px;
  }
  .relative.user_signuo_button .MuiAvatar-root {
    height: 25px;
    width: 25px;
  }
}

@media screen and (max-width: 360px) {
  .relative.user_signuo_button button {
    min-height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .display_left li {
    padding: 0 0px 0 5px;
  }
  .PopupSection .gen {
    width: 100%;
  }
  .relative.user_signuo_button .MuiAvatar-root {
    display: none;
  }
  .user_name {
    font-size: 12px;
    padding: 0 5px 0 4px;
  }
  .new_header_section ul li.haeder_signin a {
    font-size: 13px;
  }
  .sm_icon > svg {
    margin: 0 6px 0 0;
  }
  .container_padding_xs {
    padding: 0 3px;
  }
  .sm_margin_actionAree {
    margin: 0 10px 10px 10px;
  }
}

@media screen and (max-width: 320px) {
  .value.newwidth {
    width: 180px;
  }
}

/* 414 button size */
